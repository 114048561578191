/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { Card4 } from '../../../../../_metronic/partials/content/cards/Card4'
import { UserDataType } from '../../AssessorInterface';
// import { getCertificateList, getUserDetails } from '../acc';
import { getCertificateList, getUserDetails } from '../../assessorLead/components/AssessorFunction';
import FormRenderer from '../../../certificateForms/FormRender';
import { Link } from 'react-router-dom';
import { usePermissions } from '../../../../permission_component/PermissionContext';
import axios from 'axios';
interface AssessorIDProps {
  userId: string;
}interface CertificateType {
  id: string;
  name: string;
}
const VerifiedAssessorDoc: React.FC<AssessorIDProps> = ({ userId }) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  const userIdObject = JSON.parse(userId);
  const uuid = userIdObject['*'];
  const uuidRegex = /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g;
  const uuids = uuid.match(uuidRegex);
  const urlId = uuids[0]
  // const [documentAssessor, setDocumentAssessor] = useState<any>([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredValue, setFilteredValue] = useState<UserDataType[]>([]);
  // const [documents, setDocument] = useState<any>([]);
  // console.log(documents, "documents");

  const [userData, setUserData] = useState<UserDataType>();
  const [certificateList, setCertificateList] = useState<CertificateType>();
  const [formQuery, setFormQuery] = useState<any>();
  console.log(formQuery, "formQuery......printed");
  console.log(userData, "userData......printed");
  console.log(certificateList, "certificateList......printed");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserDetails(urlId);
        setUserData(result.details);
      } catch (error) {
        // Handle errors
        console.error('Error:', error);
      }
    };
    fetchData();
  }, []);

//   const fetchDocument = async () => {
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/file/structure/assessor/admin/file/?assessor=${urlId}&create_at=&update_at=`
//       )
//       setDocument(response.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   const fetchDocumentAssessor = async () => {
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/file/structure/assessor/file/?assessor=${urlId}&create_at=&update_at=`
//       )
//       setDocumentAssessor(response.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   useEffect(() => {
//     if(superUserPermission?.is_superuser === true){
//       fetchDocument();
//     }
    
//   }, [superUserPermission?.is_superuser === true])
//  useEffect(() => {
//     if(assessorPermission?.is_verify_accessor === true){
//       fetchDocumentAssessor();
//     }
    
//   }, [assessorPermission?.is_verify_accessor === true])
  useEffect(() => {
    const fetchCertificateList = async () => {
      try {
        const result = await getCertificateList();
        setCertificateList(result);
      } catch (error) {
        // Handle errors
        console.error('Error:', error);
      }
    };
    fetchCertificateList();
  }, []);

  useEffect(() => {
    if (userData?.scheme_names) {
      console.log(userData?.scheme_names);

      setFormQuery(userData?.scheme_names)
    }
  }, [userData])

  // Function to handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // Filter the value array based on the search query
    const filteredData = formQuery.filter(user => {
      const fullName = `${user?.name}`.toLowerCase();
      const searchValue = e.target.value.toLowerCase();
      return (
        fullName.includes(searchValue) ||
        user?.name.toLowerCase().includes(searchValue)

      );
    });
    setFilteredValue(filteredData);
  };


  return (
    <div id="doc_1">
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Documents
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>100+ resources</span> */}
        </h3>

        <div className='d-flex my-2'>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-250px ps-9'
              placeholder='Enter Scheme'
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          {/* {superUserPermission?.is_superuser === true && (
            <>{documents.map((document) => (
              <div key={document.id} className="document-link">
                <a
                  href={document.admin_link}
                  rel="noopener noreferrer"
                  target="_blank"
                  className='btn btn-primary btn-sm'>
                  One Drive Folder
                </a>
              </div>
            ))}
            </>
          )}  */}
         {/* {assessorPermission?.is_verify_accessor === true && (
            <>{documentAssessor.map((document) => (
              <div key={document.id} className="document-link">
                <a
                  href={document.link}
                  rel="noopener noreferrer"
                  target="_blank"
                  className='btn btn-primary btn-sm'>
                  One Drive Folder
                </a>
              </div>
            ))}
            </>
          )} */}
          {/* <a href='#' className='btn btn-primary btn-sm'>
            File Manager
          </a> */}
        </div>
      </div>

      {/* <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='Finance'
            description='7 files'
          />
        </div>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='Customers'
            description='3 files'
          />
        </div>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='CRM Project'
            description='25 files'
          />
        </div>
      </div> */}

      <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        {(searchQuery.length > 0 ? filteredValue : formQuery)?.map((item, index) => (
          <div key={item?.id} className='col-12 col-sm-12 col-xl'>
            <div className='card h-100'>
              <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                <Link id={`doc_file_${index}`} to={`details/${item?.id}`} state={{ userData: userData }} className='text-gray-800 text-hover-primary d-flex flex-column'>
                  <div className='symbol symbol-75px mb-6'>
                    <img src="/media/svg/files/pdf.svg" alt='' />
                  </div>
                  <div className='fs-5 fw-bolder mb-2'>{item?.name}</div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='row'>
        {/* <FormRenderer formIds={formQuery} /> */}
      </div>
      {/* <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/xml.svg'
            title='UTAIR CRM API Co..'
            description='2 week ago'
          />
        </div>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/tif.svg'
            title='Tower Hill App..'
            description='3 week ago'
          />
        </div>
      </div> */}
    </div>
  )
}
export { VerifiedAssessorDoc }
