import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import NCReport from '../../../nc-mangement/NCReport';
import debounce from 'lodash/debounce'

interface NCList {
  id: string;
  assessment_number: string;
  assessment_name: string;
  assessment_stage_name:string;
  category_name: string;
  ncr_status_name: string;
  registrar_name: string;
  ncr_number: string;
  date_evidence: string;
  create_at: string;
  cab_representative: string;
  team_leader_list: { id: string; first_name: string; last_name: string }[];
  scheme_list: { id: string; name: string; short_name: string }[];
  standard_list: { id: string; name: string }[];
  standard_clause: string;
  action_status: string;
  registrar_company: string;
}
interface CABIDProps {
  userId: string
}
const VerifiedAssessorNC: React.FC<CABIDProps> = ({ userId }) => {
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]

  const BASE_URL = process.env.REACT_APP_API_URL;
  const [value, setValue] = useState<any[]>([]);
  const [loader, setLoader] = useState<any>(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredValue, setFilteredValue] = useState<any[]>([]);

  const [typeFilter, setTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  const [teamLeaderData, setTeamLeaderData] = useState<NCList[]>([]);
  const [teamMemberData, setTeamMemberData] = useState<NCList[]>([]);

  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1);

  const formatDate = (dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  const handleTypeFilterChange = (type) => {
    setTypeFilter(type);
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
  };

  const handleDateFilterChange = (dateRange) => {
    setDateFilter(dateRange);
  };

  const applyFilters = (data) => {
    let filteredData = data;

    if (searchQuery) {
      filteredData = filteredData.filter((user) => {
        const fullName = `${user?.registrar_name}`.toLowerCase();
        const searchValue = searchQuery.toLowerCase();
        const categoryName = `${user?.category_name}`.toLowerCase();
        const ncName = `${user?.ncr_status_name}`.toLowerCase();

        return (
          fullName.includes(searchValue) ||
          categoryName.includes(searchValue) ||
          ncName.includes(searchValue)
        );
      });
    }

    if (typeFilter) {
      filteredData = filteredData.filter((item) =>
        item.category_name.toLowerCase() === typeFilter.toLowerCase()
      );
    }

    if (statusFilter) {
      filteredData = filteredData.filter((item) =>
        item.ncr_status_name.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    if (dateFilter) {
      const now = moment();
      filteredData = filteredData.filter((item) => {
        const raiseDate = moment(item.create_at);
        switch (dateFilter) {
          case '1month':
            return raiseDate.isAfter(now.clone().subtract(1, 'month'));
          case '3months':
            return raiseDate.isAfter(now.clone().subtract(3, 'months'));
          case '6months':
            return raiseDate.isAfter(now.clone().subtract(6, 'months'));
          case '1year':
            return raiseDate.isAfter(now.clone().subtract(1, 'year'));
          default:
            return true;
        }
      });
    }

    return filteredData;
  };


  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`${BASE_URL}/nc/management/filter/pagination/?assessor=${urlId}&page=${pagenumber}`);
      if (response.status === 200) {
        setValue(response?.data?.results);
        const pages = Math.ceil(response?.data?.count / 10);

        setTotalPages(pages);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [pagenumber]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoader(true);

  //       // Fetch data for team leader
  //       const teamLeaderResponse = await axios.get<NCList[]>(`${BASE_URL}/nc/management/filter/?team_leader=${urlId}`);
  //       if (teamLeaderResponse.status === 200) {
  //         setTeamLeaderData(teamLeaderResponse.data);
  //       }

  //       // Fetch data for team member
  //       const teamMemberResponse = await axios.get<NCList[]>(`${BASE_URL}/nc/management/filter/?team_member=${urlId}`);
  //       if (teamMemberResponse.status === 200) {
  //         setTeamMemberData(teamMemberResponse.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setLoader(false);
  //     }
  //   };

  //   fetchData();
  // }, [BASE_URL, urlId]);
  // const combinedData = [...teamLeaderData, ...teamMemberData];
  // const uniqueData = combinedData.filter((item, index, self) =>
  //   index === self.findIndex((t) => (
  //     t.id === item.id // assuming 'id' is a unique identifier for NCList items
  //   ))
  // );

  const totalPagesArray = Array.from({ length: totalPages }, (_, index) => index + 1);
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page);
    }
  };

  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value;
      console.log(searchValue, 'searchValue');
      if (searchValue) {
        try {
          // setLoader(true);
          const response = await axios.get(
            `${BASE_URL}/nc/management/filter/pagination/?assessor=${urlId}&search=${searchValue}`
          );
          // settotalcount(response?.data?.count);
          if (response.status === 200) {
            setValue(response.data?.results);
          }
        } catch (error) {
          console.log(error);
        } finally {
          // setLoader(false);
        }
      } else {
        setValue([]);
        fetchData();
      }
    }, 500),
    []
  );

  return (
    <div className='card card-flush'>
      {/* <div className='card-header pt-5'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x='17.0365'
                  y='15.1223'
                  width='8.15546'
                  height='2'
                  rx='1'
                  transform='rotate(45 17.0365 15.1223)'
                  fill='currentColor'
                />
                <path
                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              onChange={handleSearchChange}
              placeholder='Search user'
            />
          </div>
        </div>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            <Link to='/NCManagement/form/' className='btn btn-primary'>
              <span className='svg-icon svg-icon-2'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='11.364'
                    y='20.364'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(-90 11.364 20.364)'
                    fill='currentColor'
                  />
                  <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='currentColor' />
                </svg>
              </span>
              Create NC
            </Link>
          </div>
        </div>
      </div>
      <div className='card-header pt-5'>
        <div className='d-flex justify-content-between'>
          <DropdownButton id="type-filter" title="Filter by Type" className='m-5'>
            <Dropdown.Item onClick={() => handleTypeFilterChange('')}>All</Dropdown.Item>
            <Dropdown.Item onClick={() => handleTypeFilterChange('Major')}>Major</Dropdown.Item>
            <Dropdown.Item onClick={() => handleTypeFilterChange('Minor')}>Minor</Dropdown.Item>
          </DropdownButton>
          <DropdownButton id="status-filter" title="Filter by Status" className='m-5'>
            <Dropdown.Item onClick={() => handleStatusFilterChange('')}>All</Dropdown.Item>
            <Dropdown.Item onClick={() => handleStatusFilterChange('Open')}>Open</Dropdown.Item>
            <Dropdown.Item onClick={() => handleStatusFilterChange('Close')}>Close</Dropdown.Item>
          </DropdownButton>
          <DropdownButton id="date-filter" title="Filter by Raise Date" className='m-5'>
            <Dropdown.Item onClick={() => handleDateFilterChange('')}>All</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDateFilterChange('1month')}>Last 1 Month</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDateFilterChange('3months')}>Last 3 Months</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDateFilterChange('6months')}>Last 6 Months</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDateFilterChange('1year')}>Last 1 Year</Dropdown.Item>
          </DropdownButton>
        </div>
      </div> */}
      <div className='card-body pt-5 table-responsive'>
        <div className='d-flex align-items-center position-relative my-1'>
          <span className='svg-icon svg-icon-1 position-absolute ms-6'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                opacity='0.5'
                x='17.0365'
                y='15.1223'
                width='8.15546'
                height='2'
                rx='1'
                transform='rotate(45 17.0365 15.1223)'
                fill='currentColor'
              />
              <path
                d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                fill='currentColor'
              />
            </svg>
          </span>
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-250px ps-14"
            onChange={handleSearchChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchChange(e);
              }
            }}
            placeholder="Search user"
          />
        </div>
        <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
          <thead>
            <tr className='text-start  fw-bold  text-uppercase gs-0'>
              <th className='min-w-50px'>SR#</th>
              <th className='min-w-125px'>Assessment#</th>
              <th className='min-w-125px'>Assessment Stage</th>
              <th className='min-w-125px'>Assessment Type</th>
              <th className='min-w-130px'>NC#</th>
              <th className='min-w-130px'>CAB</th>
              <th className='min-w-120px'>Type</th>
              {/* <th className='min-w-120px'>Stage</th> */}
              <th className='min-w-110px'>Status</th>
              <th className='min-w-125px'>Standard</th>
              <th className='min-w-125px'>Raise Date</th>
              {/* <th className='min-w-125px'>Submission Date</th> */}
              <th className='min-w-125px'>Team Lead</th>
              {/* <th className='min-w-125px'>CAB Representative</th> */}
              <th className='min-w-70px'>Report</th>
              <th className='min-w-70px'>Link</th>
              {/* <th className='text-center min-w-100px'>Action</th> */}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-semibold'>
            {value.map((item, index) => (
              <tr key={index}>
                <td>
                  {/* <Link
                    to={`/assessor/verified/${item?.id}/overview`}
                    className='text-gray-800 text-hover-primary mb-1'
                  > */}
                  {index + 1}
                  {/* </Link> */}
                </td>
                {/* <td>{item?.registrar_name}</td> */}
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.assessment_number}</div>
                </td>
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.assessment_stage_name}</div>
                </td>
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.assessment_name}</div>
                </td>
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.ncr_number}</div>
                </td>
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.registrar_company}</div>
                </td>
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.category_name}</div>
                </td>
                {/* <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.action_status}</div>
                </td> */}
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    {item?.ncr_status_name}
                  </div>
                </td>
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    {item?.standard_clause}
                  </div>
                </td>
                <td>{item && item?.create_at ? formatDate(item?.create_at) : ''}</td>
                {/* <td>{item && item.date_evidence ? formatDate(item.date_evidence) : ''}</td> */}
                <td>
                  {item?.team_leader_list.map((leader) => leader?.first_name + ' ' + leader?.last_name).join(', ')}
                </td>
                {/* <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    {item?.cab_representative}
                  </div>
                </td> */}
                <td>
                  <a
                    href={`/apps/cab/nc/report/${item?.id}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src='/media/doc.png' alt='' />
                  </a>
                </td>
                {/* <td className='text-center'>
                  <Link to={`/NCManagement/edit/${item?.id}/`}>
                    <i
                      className='bi bi-pencil-square text-primary'
                      style={{ fontSize: '25px', cursor: 'pointer' }}
                    ></i>
                  </Link>
                </td> */}
                <td>
                {item?.assessor_urls?.url_value ?(
                  <a href={item?.assessor_urls?.url_value}
                  target='_blank'>
                <i className="bi bi-box-arrow-up-right text-primary text-hover-primary fs-20" ></i>
                </a>):null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ul className='pagination'>
          <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
            <a href='#' className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
              <i className='previous'></i>
            </a>
          </li>
          {totalPagesArray.map((page) => (
            <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
              <a href='#' className='page-link' onClick={() => handlePageChange(page)}>
                {page}
              </a>
            </li>
          ))}
          <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
            <a href='#' className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
              <i className='next'></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default VerifiedAssessorNC