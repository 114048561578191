import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import axios from 'axios'
import {Link} from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {toast} from 'react-toastify'
import UAFLoader from '../../../../../../loader/UafLoader'
import moment from 'moment'
const API_URL = process.env.REACT_APP_API_URL
interface SchemeData {
  scheme_id: string
  valid_date: string
  expiry_date: string
  issue_number: string
}

interface CertficateData {
  total_count: string
  country: string
  country__name: string
}
type Props = {
  uniqueCab: string | undefined
}
interface transferCertificateData {
  transferred: string
  country: string
  country__name: string
}
const MainProgram = ({uniqueCab}: Props) => {
  const [certificateData, setCertificateData] = useState<CertficateData[]>([])
  const [arcData, setARCData] = useState<any>()
  const [schemeData, setSchemeData] = useState<SchemeData>()
  const [transferCertificate, settransferCertificate] = useState<transferCertificateData[]>([])
  const [formData, setFormData] = useState<any>({})
  const [cabData, setCabData] = useState<any>()
  const [selectedCycle, setSelectedCycle] = useState(null)
  const [cycles, setCycles] = useState<string[]>([])
  const [selectedPrograms, setSelectedPrograms] = useState<any>()
  const [globalData, setGlobalData] = useState<any>()
  const [userPrograms, setUserPrograms] = useState<any[]>([])
  const [loader, setLoader] = useState<any>(false)
  console.log(cabData, 'this is my CABdata')

  const [highestCycle, setHighestCycle] = useState<number | null>(null)
  const cabID = uniqueCab
  const today = new Date()

  // Get the date one year earlier
  const lastYear = new Date()
  lastYear.setFullYear(today.getFullYear() - 1)

  // Format the dates as required (ISO 8601 format)
  const formatDate = (date) => date.toISOString()

  const startDate = formatDate(lastYear)
  const endDate = formatDate(today)

  const getUserDetails = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/account/patch/user/${cabID}/`)
      .then((response) => {
        const result = response.data
        // setUserData(result)
        setUserPrograms(result?.programme_names)
      })
      .catch((error) => {
        // Handle errors
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    getUserDetails()
  }, [cabID])

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const response = await axios.get(`${API_URL}/certificate/country/list/${cabID}/`)
        setCertificateData(
          response?.data?.certificates?.sort((a, b) => b.total_count - a.total_count)?.slice(0, 6)
        )
      } catch (error) {
        console.error('Error fetching standard data:', error)
      }
    }
    fetchCertificate()
  }, [cabID])

  useEffect(() => {
    const fetchtransferCertificate = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/certificate/scheme/list/data/?cab=${cabID}&start_date=${startDate}&end_date=${endDate}`
        )
        settransferCertificate(
          response?.data?.certificates?.sort((a, b) => b.total_count - a.total_count)?.slice(0, 6)
        )
      } catch (error) {
        console.error('Error fetching standard data:', error)
      }
    }
    fetchtransferCertificate()
  }, [cabID])

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${API_URL}/arc/management/schedule/data/filter/?cab=${cabID}`
  //     )

  //     setCabData(response?.data[0]?.cab_data)
  //     setARCData(response?.data[0])
  //     setSchemeData(response?.data[0]?.scheme_data)

  //   } catch (error) {
  //     console.error('Error fetching assessment data:', error)
  //   }
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [cabID])

  // const fetchARCData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${API_URL}/arc/management/schedule/data/filter/?cab=${cabID}&cycle=${selectedCycle}`
  //     )
  //     setCabData(response?.data[0]?.cab_data)
  //     setARCData(response?.data[0])
  //     setSchemeData(response?.data[0]?.scheme_data)
  //   } catch (error) {
  //     console.error('Error fetching assessment data:', error)
  //   }
  // }
  // useEffect(() => {
  //   if (selectedCycle) {
  //     fetchARCData()
  //   }
  // }, [selectedCycle])

  // const handleCycleSelect = (selectedOption: any) => {
  //   setSelectedCycle(selectedOption.value + 1)
  // }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleDateChange = (date: Date, name: string) => {
    setFormData({
      ...formData,
      [name]: date,
    })
  }

  const handleSelectedProgram = async (selectedOptions) => {
    setSelectedPrograms(selectedOptions.value)

    try {
      const response = await axios.get(
        `${API_URL}/arc/management/schedule/data/filter/?cab=${cabID}&programme=${selectedOptions.value}`
      )

      // const fetchedARCData = response?.data

      setCabData(response?.data[0]?.cab_data)
      setARCData(response?.data[0])
      setSchemeData(response?.data[0]?.scheme_data)
    } catch (error) {
      console.error('Error fetching assessment data:', error)
    }
  }

  const handleSave = async () => {
    const formatDate = (date: Date | null) => {
      if (!date) return null

      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    }

    const accreditationDates =
      schemeData &&
      Object.entries(schemeData).map(([key, option]) => ({
        id: option?.scheme_id,
        date: option?.valid_date,
        scheme_name: key,
      }))

    const reaccreditationDates =
      schemeData &&
      Object.entries(schemeData).map(([key, option]) => ({
        id: option?.scheme_id,
        date: formatDate(formData[`reaccreditation_date_${option?.scheme_id}`]),
        scheme_name: key,
      }))

    const expiryDates =
      schemeData &&
      Object.entries(schemeData).map(([key, option]) => ({
        id: option?.scheme_id,
        date: option?.expiry_date,
        scheme_name: key,
      }))

    const reaccreditationDueDates =
      schemeData &&
      Object.entries(schemeData).map(([key, option]) => ({
        id: option?.scheme_id,
        date: formatDate(formData[`reaccreditation_due_date_${option?.scheme_id}`]),
        scheme_name: key,
      }))

    const dataToSave = {
      cab: cabID,

      accreditation_dates: JSON.stringify(accreditationDates),
      reaccreditation_dates: JSON.stringify(reaccreditationDates),
      expiry_dates: JSON.stringify(expiryDates),
      reaccreditation_due_dates: JSON.stringify(reaccreditationDueDates),
      reason_for_updating: formData.reason_for_updating,
      assessment_frequency: formData.assessment_frequency,
      uaf_noncompliance: formData.uaf_noncompliance,
      followup_issue: formData.followup_issue,
      iaf15_review: formData.iaf15_review,
      sampling_transfer: formData.sampling_transfer,
      last_assessment_country: formData.last_assessment_country,
      cab_related_bodies: formData.cab_related_bodies,
      feedback: formData.feedback,
      another_status: formData.another_status,
      witnessing_details: formData.witnessing_details,
      applicable_standards: formData.applicable_standards,
    }

    try {
      setLoader(true)
      const response = await axios.post(`${API_URL}/cab/global/scheme/data/post/`, dataToSave)

      if (response.status === 200) {
        setLoader(false)

        toast.success('Data Submitted')
        setFormData({})
      } else {
        setLoader(false)
        toast.error('Failed to submit data')
      }
    } catch (error) {
      setLoader(false)
      toast.error('Error saving data')
    }
  }

  //   const highest = arcData
  //   ? Math.max(...arcData.map((item) => parseInt(item.cycle, 10)))
  //   : 0;
  // setHighestCycle(highest);

  return (
    <>
      {loader ? <UAFLoader /> : null}
      <div className='mb-5 mb-xl-8'>
        <div className='card-header border-0 py-5 d-flex mb-5'>
          {/* <h3 className='card-title align-items-start flex-column mt-2'>
          <span className='card-label fw-bold fs-1'>Global Assessment</span>
        </h3> */}
        </div>
        <div className='card-body py-3'>
          <div className='col-xl-4 card card-xl-stretch mb-5 mb-xl-10'>
            <div className='p-3 d-flex justify-content-between w-100 align-items-center'>
              <div className=' col-12 m-2'>
                {/* <label className=' form-label'>Select Program</label> */}
                <Select
                  className='react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  options={userPrograms?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  placeholder='Select Program'
                  onChange={handleSelectedProgram}
                  value={selectedPrograms?.name}
                />
              </div>
            </div>
          </div>
          {/* <div className='col-xl-4 card card-xl-stretch mb-5 mb-xl-10'>
            <div className='p-3 d-flex justify-content-between w-100 align-items-center'>
              <div className=' col-12 m-2'>
                <Select
                  className='react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  placeholder='Select Cycle'
                  options={cycles.map((cycle, index) => ({
                    label: `Cycle${index + 1}`,
                    value: index,
                  }))}
                  onChange={handleCycleSelect}
                />
              </div>
            </div>
          </div> */}
          <div className='table-responsive'>
            <table className='align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Name of Conformity Assessment Body
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    {cabData?.cab_company}
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Date of Accreditation and Re-Accreditation for each management system
                    (MM-DD-YYYY)
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    <table style={{borderCollapse: 'collapse', width: '100%'}}>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '8px'}}>Scheme</th>
                        {arcData?.scheme_data &&
                          Object.entries(arcData.scheme_data).map(([key, value]) => (
                            <td style={{border: '1px solid #dddddd', padding: '8px'}} key={key}>
                              {key}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '8px'}}>
                          Accreditation date
                        </th>
                        {schemeData &&
                          Object.entries(schemeData).map(([key, option]) => (
                            <td
                              style={{
                                border: '1px solid #dddddd',
                                padding: '8px',
                                minWidth: '120px',
                              }}
                              key={key}
                            >
                              <input
                                type='date'
                                value={option?.valid_date}
                                className='react_date_picker form-control form-control-solid'
                                style={{width: '100%'}}
                              />
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '8px'}}>
                          Re-Accreditation date
                        </th>
                        {schemeData &&
                          Object.entries(schemeData).map(([key, option]) => (
                            <td style={{border: '1px solid #dddddd', padding: '8px'}} key={key}>
                              <ReactDatePicker
                                selected={formData[`reaccreditation_date_${option?.scheme_id}`]}
                                placeholderText='Select Date'
                                className='react_date_picker form-control form-control-solid'
                                onChange={(date) =>
                                  handleDateChange(
                                    date as Date,
                                    `reaccreditation_date_${option?.scheme_id}`
                                  )
                                }
                                dropdownMode='select'
                                showMonthDropdown
                                showYearDropdown
                              />
                            </td>
                          ))}
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Accreditation Expiry and Reaccreditation Due Date
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    <table style={{borderCollapse: 'collapse', width: '100%'}}>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '8px'}}>Scheme</th>
                        {arcData?.scheme_data &&
                          Object.entries(arcData.scheme_data).map(([key, value]) => (
                            <td style={{border: '1px solid #dddddd', padding: '8px'}} key={key}>
                              {key}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '8px'}}>
                          Current Expiry
                        </th>
                        {schemeData &&
                          Object.entries(schemeData).map(([key, option]) => (
                            <td
                              style={{
                                border: '1px solid #dddddd',
                                padding: '8px',
                                minWidth: '120px',
                              }}
                              key={key}
                            >
                              <input
                                type='date'
                                value={option?.expiry_date}
                                className='react_date_picker form-control form-control-solid'
                                style={{width: '100%'}}
                              />
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '8px'}}>
                          Reaccreditation assessment Due Month
                        </th>
                        {schemeData &&
                          Object.entries(schemeData).map(([key, option]) => (
                            <td style={{border: '1px solid #dddddd', padding: '8px'}} key={key}>
                              <ReactDatePicker
                                selected={formData[`reaccreditation_due_date_${option?.scheme_id}`]}
                                placeholderText='Select Date'
                                className='react_date_picker form-control form-control-solid'
                                onChange={(date) =>
                                  handleDateChange(
                                    date as Date,
                                    `reaccreditation_due_date_${option?.scheme_id}`
                                  )
                                }
                                dropdownMode='select'
                                showMonthDropdown
                                showYearDropdown
                              />
                            </td>
                          ))}
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Head office/Main Critical Location
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    {cabData?.cab_address?.address}
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Other Key Locations
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    {arcData?.other_key_location}
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Other Non-Key Locations
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    {arcData?.other_location}
                  </td>
                </tr>
               
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Month of Key Locations
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    {arcData?.accreditation_valid_date
                      ? moment(arcData?.accreditation_valid_date).format('MM-YYYY')
                      : ''}
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Reason for updating
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    <textarea
                      name='reason_for_updating'
                      value={formData.reason_for_updating || ''}
                      onChange={handleChange}
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Assessment Frequency
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    <textarea
                      name='assessment_frequency'
                      value={formData.assessment_frequency || ''}
                      onChange={handleChange}
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                </tr>

                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Factors considered for developing Assessment Program
                  </th>
                  <tr className='fw-bold text-dark bg-light w-100' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      UAF policies’ noncompliance
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='uaf_noncompliance'
                        value={formData.uaf_noncompliance || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Follow-ups from last assessments or any open issues
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='followup_issue'
                        value={formData.followup_issue || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Review of IAF MD 15 compliance
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='iaf15_review'
                        value={formData.iaf15_review || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    (Activities, locations as per IAF MD 12 & 23 and personnel covered by the scope
                    of accreditation)
                  </th>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of CAB other critical locations(s) or locations(s) apart from head
                      office(Main Location)
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='sampling_transfer'
                        value={formData.sampling_transfer || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      List of the top six countries in which certificates are issued for the
                      accreditation cycle (samples to be taken from these countries)
                    </th>
                    {certificateData
                      .filter((item) => Number(item?.total_count) > 0)
                      .map((item, index) => (
                        <div key={index}>
                          {item.country__name}: {item.total_count}
                        </div>
                      ))}
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of countries accredited for sampling where a there is significant
                      number of active certificates issued since accreditation
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      {transferCertificate
                        .filter((item) => Number(item?.transferred) > 0)
                        .map((item, index) => (
                          <div key={index}>
                            {item.country__name}: {item.transferred}
                          </div>
                        ))}
                    </td>
                  </tr>

                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of countries added in most recent assessment
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='last_assessment_country'
                        value={formData.last_assessment_country || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of CAB’s Related Body, If any
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='cab_related_bodies'
                        value={formData.cab_related_bodies || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of any Adverse feedback or complaint
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='feedback'
                        value={formData.feedback || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      CAB accreditation status with another AB (if yes, mention the accredited
                      schemes and AB)
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='another_status'
                        value={formData.another_status || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of Adjustment of the witnessing frequency as per the applicable IAF MD
                      requirements
                    </th>
                    <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='witnessing_details'
                        value={formData.witnessing_details || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '30%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Applicable Accreditation Standards
                  </th>
                  <td style={{width: '70%', border: '1px solid #dddddd', padding: '8px'}}>
                    <textarea
                      name='applicable_standards'
                      value={formData.applicable_standards || ''}
                      onChange={handleChange}
                      className='form-control'
                      rows={10}
                    ></textarea>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
          <div className='mb-5 mt-5'>
            <button className='btn btn-primary mt-3' onClick={handleSave}>
              Save
              <i className='fa fa-angle-double-right' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainProgram
