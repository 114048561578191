import React from 'react';

const NumberTOWord = (num: number): string => {
  const ones = [
    "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
  ];
  const teens = [
    "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
    "seventeen", "eighteen", "nineteen",
  ];
  const tens = [
    "", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety",
  ];
  const scales = ["", "thousand", "million", "billion"];

  if (num === 0) return "zero";

  let words = "";

  const chunkify = (n: number): number[] => {
    const chunks: number[] = [];
    while (n > 0) {
      chunks.push(n % 1000);
      n = Math.floor(n / 1000);
    }
    return chunks;
  };

  const chunkToWords = (chunk: number): string => {
    let chunkWords = "";

    if (chunk >= 100) {
      chunkWords += ones[Math.floor(chunk / 100)] + " hundred ";
      chunk %= 100;
    }
    if (chunk >= 11 && chunk <= 19) {
      chunkWords += teens[chunk - 11] + " ";
    } else {
      if (chunk >= 10) {
        chunkWords += tens[Math.floor(chunk / 10)] + " ";
        chunk %= 10;
      }
      chunkWords += ones[chunk] + " ";
    }
    return chunkWords.trim();
  };

  const chunks = chunkify(num);

  chunks.forEach((chunk, i) => {
    if (chunk > 0) {
      words = `${chunkToWords(chunk)} ${scales[i]} ${words}`;
    }
  });

  return words.trim();
};

export default NumberTOWord;
