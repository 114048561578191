/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {KTIcon, KTSVG} from '../../../../../_metronic/helpers'
import {
  ChartsWidget1,
  ListsWidget5,
  TablesWidget1,
  TablesWidget5,
} from '../../../../../_metronic/partials/widgets'
import ActivityLogs from './ActivityLogs'
import '../../../../../_metronic/assets/css/businessCss.css'
import {UserDataType} from '../../AssessorInterface'
import {getUserDetails} from './AssessorFunction'
import {Button, Dropdown, DropdownButton, Modal} from 'react-bootstrap'
import _ from 'lodash'
import UAFLoader from '../../../../loader/UafLoader'
interface AssessorIDProps {
  userId: string
}

const AssessorDetails: React.FC<AssessorIDProps> = ({userId}) => {
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]
  console.log(urlId, 'urlIdurlIdurlId.......')
  const [userDataIAF, setUserDataIAF] = useState<UserDataType>()
  const [formQuery, setFormQuery] = useState<any>()
  const [schemeQuery, setSchemeQuery] = useState<any>()
  const [userData, setUserData] = useState<UserDataType>()
  console.log(userData, 'USERDATA')
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')
  
  const [loader, setLoader] = useState<any>(false);

  const handlePdfIconClick = (url) => {
    setPdfUrl(url)
    setShowPdfModal(true)
  }
  const handleClosePdfModal = () => {
    setShowPdfModal(false)
    setPdfUrl('') // Clear the PDF URL when the modal is closed
  }

  useEffect(() => {
    if (userData?.programme_names) {
      console.log(userData?.programme_names)
      setFormQuery(userData?.programme_names)
    }
  }, [userData])
  useEffect(() => {
    if (userData?.scheme_names) {
      console.log(userData?.scheme_names)
      setSchemeQuery(userData?.scheme_names)
    }
  }, [userData])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true)
        const result = await getUserDetails(urlId)
        setUserData(result.details)
        setUserDataIAF(result.iaf_critical)
      } catch (error) {
        // Handle errors
        console.error('Error:', error)
      }
      setLoader(false)
    }
    fetchData()
  }, [])
  const groupedData = _.groupBy(userDataIAF, 'scheme_name')
  console.log(groupedData, 'this is grouped')

  return (
    <>
    {loader ? <UAFLoader /> : null}
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-4'>
          <div className='card card-xl-stretch mb-5 mb-xl-8' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Assessor Details</h3>
              </div>

              {/* <Link to='/organisation/businessunit/setting' className='btn btn-primary align-self-center'>
        Edit Profile
      </Link> */}
            </div>

            <div className='card-body p-9'>
              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {userData?.first_name} {userData?.last_name}
                  </span>
                </div>
              </div>

              {userData?.company ? (<div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Company</label>

                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>{userData?.company}</span>
                </div>
              </div>):null}

              {userData?.phone ? (<div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Contact Phone

                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{userData?.phone}</span>


                </div>
              </div>):null}

              {userData?.company_web ? ( <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Company Site</label>

                <div className='col-lg-8'>
                  <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                    {userData?.company_web}
                  </a>
                </div>
              </div>):null}

              {userData?.country_name ? ( <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Country
                  <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i>
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{userData?.country_name}</span>
                </div>
              </div>):null}

              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Communication</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
                </div>
              </div>
              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Resume:</label>

                <div className='col-lg-8'>
                  {userData?.resume ? (
                    <a onClick={() => handlePdfIconClick(userData?.resume)}>
                      
                      <img src='/media/doc.png' alt='' />
                    </a>
                  ) : null}
                </div>
              </div>

              {/* <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-muted'>Allow Changes</label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6'>Yes</span>
          </div>
        </div> */}

              {/*   <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
        <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
        <div className='d-flex flex-stack flex-grow-1'>
          <div className='fw-bold'>
            <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
            <div className='fs-6 text-gray-600'>
              Your payment was declined. To start using tools, please
              <Link className='fw-bolder' to='/crafted/account/settings'>
                {' '}
                Add Payment Method
              </Link>
              .
            </div>
          </div>
        </div>
      </div> */}
            </div>
          </div>
        </div>
        <div className='col-xl-4'>
          <div className='card card-xl-stretch mb-5 mb-xl-8'>
            <div className='card-header border-1'>
              <h3 className='card-title fw-bold text-dark'>Programs Applied for</h3>
            </div>
            <div className='card-body pt-6'>
              {formQuery?.map((item) => (
                <div className='d-flex align-items-center mb-8' key={item?.id}>
                  <span className='bullet bullet-vertical h-40px bg-primary'></span>
                  <div className='flex-grow-1' style={{marginLeft: '10px'}}>
                    <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                      {item?.name}
                    </a>
                    {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
                  </div>
                  <span className='badge badge-light-primary fs-8 fw-bold'>Applied</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='col-xl-4'>
          <div className='card card-xl-stretch mb-5 mb-xl-8'>
            <div className='card-header border-1'>
              <h3 className='card-title fw-bold text-dark'>Schemes Applied for</h3>
            </div>
            <div className='card-body pt-6 accordion' id='accordionExample'>
              {Object.keys(groupedData)?.map((schemeName, index) => (
                <div className='accordion-item' key={index}>
                  <h2 className='accordion-header' id={`heading${index}`}>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#collapse${index}`}
                      aria-expanded='true'
                      aria-controls={`collapse${index}`}
                    >
                      <span className='bullet bullet-vertical h-40px bg-primary'></span>
                      <div className='flex-grow-1' style={{marginLeft: '10px'}}>
                        <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
                          {schemeName}
                        </span>
                      </div>
                      <span className='badge badge-light-primary fs-8 fw-bold'>Applied</span>
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className='accordion-collapse collapse show' // Add the 'show' class here
                    aria-labelledby={`heading${index}`}
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      {groupedData[schemeName].map((item, idx) => (
                        <li key={idx}>{item.cluster_name}</li>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='row gy-10 gx-xl-10'> */}
      {/* <div className='col-xl-4'>
        <div className="card card-xxl-stretch mb-5 mb-xl-10">

          <div className='card-header border-1 pt-5'>

            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Schemes Applied for</span>


            </h3>
          </div>
          <div className='card-body scroll-container'>
          {schemeQuery?.map((item) => (
            <div className='d-flex align-items-center mb-8' key={item}>
              <span className='bullet bullet-vertical h-40px bg-primary'></span>
              <div className='flex-grow-1' style={{ marginLeft: "10px" }}>
                <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                  {item}
                </a>

              </div>
              <span className='badge badge-light-primary fs-8 fw-bold'>Applied</span>
            </div>
          ))}
          </div>
        </div>

      </div> */}

      {/* <div className='col-xl-6'> */}
      {/* <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
      {/* </div> */}
      {/* </div> */}
      {/* <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-6'>
        <div className="card card-xxl-stretch mb-5 mb-xl-10">

          <div className='card-header border-0 pt-5'>

            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Activity Logs</span>


            </h3>
          </div>
          <div className='card-body scroll-container'>
            <ActivityLogs />
          </div>
        </div>

      </div>

      <div className='col-xl-6'>
        <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </div> */}

      {/* <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-6'>
        <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>

      <div className='col-xl-6'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </div> */}
      <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display the PDF in the modal */}
          <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe>
          <a href={pdfUrl} download style={{display: 'block', marginTop: '10px'}}>
          <button className='btn btn-primary btn-sm ' type='button'>Download</button>
          </a>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {AssessorDetails}
