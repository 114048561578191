import axios from 'axios'
import React, {useState, ChangeEvent, useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'
import UAFLoader from '../../../../loader/UafLoader'
import {getUserDetails} from '../components/VerifiedAssessorFunction'
const API_URL = process.env.REACT_APP_API_URL
type AssessorPlannerData = {
  id: string
  cab_name: string
  assessor_name: {id: string; first_name: string; last_name: string}[]
  scheme_name: string
  assessor_data: {
    location: string
    assessor: string
    assessor__first_name: string
    assessor__last_name: string
  }[]
  assessment_multi_date: string
  assessment_number: string
  assessment_type: string
  assessment_duration: string
  remark: string
  is_approved: boolean
  is_rejected: boolean
  status: string | null
  report: string | null
  report_remark: string | null
  create_at: string
  update_at: string
  scheme: string
  assessor_planner: string
  cab: string
  assessor: string[]
  cluster_values: {id: string; name: string}[]
}
type Props = {
  show: boolean
  handleClose: () => void
  refresh: () => void
  singleRow: AssessorPlannerData
  rowStatus: string
}
interface Document {
  name: string;
  file: File;
}
const ReviewDocumentModal = ({show, handleClose, refresh, singleRow, rowStatus}: Props) => {
  const [selectCluster, setSelectCluster] = useState<any[]>([])
  const [selectedCluster, setSelectedCluster] = useState<any[]>([])
  const [loader, setLoader] = useState<any>(false)
  const [estimationDocuments, setEstimationDocuments] = useState<{
    documentFiles: Document[];
    remarks: string[];
  }>({
    documentFiles: [],
    remarks: [],
  });
  
  console.log(estimationDocuments,"estimationDocuments");
  const documentFileUploader = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newDocuments = Array.from(e.target.files).map((file) => ({
        name: file.name,
        file,
      }));
      setEstimationDocuments((prev) => ({
        documentFiles: [...prev.documentFiles, ...newDocuments],
        remarks: [...prev.remarks, ...new Array(newDocuments.length).fill('')], // Initialize empty remarks
      }));
    }
  };
  
  // Remove document function
  const removeDocument = (indexToRemove: number) => {
    const filteredDocuments = estimationDocuments.documentFiles.filter(
      (file, index) => index !== indexToRemove
    );
    setEstimationDocuments({ ...estimationDocuments, documentFiles: filteredDocuments });
  };
  useEffect(() => {
    if (show && singleRow) {
      getSchemeClusterName()
      console.log('Setting estimation details with singleRow data')
      // setEstimationDetails((prevDetails) => ({
      //   ...prevDetails,
      //   remarks: singleRow.report_remark || '',
      // }))
      setSelectedCluster(singleRow?.cluster_values?.map((item) => item?.id) || [])
    }
  }, [show, singleRow])
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    // Call both APIs independently
    let firstApiSuccess = false;
  
    // Check if assessment type is 'Witness Assessment'
    if (singleRow?.assessment_type === 'Witness Assessment') {
      try {
        // First API request (status update)
        const formData = new FormData();
        formData.append('status', rowStatus);
        formData.append('cluster', JSON.stringify(selectedCluster));
       
  
        const response = await axios.put(
          `${API_URL}/cab/assessor/planner/scheme/${singleRow.id}/new/update/`,
          formData
        );
  
        if (response.status === 200 || response.status === 201) {
          firstApiSuccess = true;
          toast.success('Status updated successfully.');
        } else {
          toast.error('Failed to update status.');
        }
      } catch (error) {
        toast.error('Failed to update status. Proceeding with document upload.');
      }
    } else {
      // Skip the first API call if assessment type is not 'Witness Assessment'
      firstApiSuccess = true; // Consider it successful to proceed with document upload
    }
  
    // Second API requests for each document (always executed)
    try {
      setLoader(true)
      for (let i = 0; i < estimationDocuments.documentFiles.length; i++) {
        const docData = new FormData();
        if (singleRow?.assessment_type === 'Witness Assessment'){
        docData.append('assessor_planner', singleRow.id);}
        else{
          docData.append('assessor_planner', singleRow.assessor_planner);}
        docData.append('remark', estimationDocuments.remarks[i]);
        docData.append('report', estimationDocuments.documentFiles[i].file);
        docData.append('cab', singleRow.cab);
        if (singleRow?.assessment_type === 'Witness Assessment'){
          await axios.post(
            `${API_URL}/cab/assessor/planner/scheme/upload/multi/docs/`,
            docData
          );

        }
        else{
          await axios.post(
            `${API_URL}/cab/assessor/planner/upload/multi/docs/`,
            docData
          );

        }
        
        setLoader(false)
      }
      // toast.success('Documents uploaded successfully.');
    } catch (error) {
      setLoader(false)
      toast.error('Failed to upload documents.');
    }
  
    // Close modal and refresh regardless of success/failure
    handleClose();
    refresh();
    setSelectedCluster([]);
    setEstimationDocuments({ documentFiles: [], remarks: [] });
  
    if (firstApiSuccess) {
      toast.success('Application submitted successfully.');
    }
  };
  
  
  
  const getSchemeClusterName = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/global/cluster/filter/?scheme=${singleRow?.scheme}`
      )
      setSelectCluster(response.data)
    } catch (error) {
      console.error('API Error:', error)
      // throw error;
    }
  }
  useEffect(() => {
    if (show === true && singleRow && singleRow?.assessment_type === 'Witness Assessment') {
      getSchemeClusterName()
    }
    //   getSchemeClusterName()
  }, [singleRow])
  const handleCheckboxChange = (clusterId) => {
    setSelectedCluster((prevSelectedCluster) => {
      if (prevSelectedCluster.includes(clusterId)) {
        return prevSelectedCluster.filter((id) => id !== clusterId)
      } else {
        // Add the clusterId if it is not already selected
        return [...prevSelectedCluster, clusterId]
      }
    })
  }
  return (
    <>
    {loader ? <UAFLoader /> : null}
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{singleRow?.assessment_type}{' '}Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{singleRow?.scheme_name}</h3>
        <div className='row p-5'>
          {singleRow?.assessment_type === 'Witness Assessment' && (
            <div className='mb-5 col-12'>
              {selectCluster.map((item, index) => (
                <div key={index}>
                  <input
                    type='checkbox'
                    value={item.id}
                    onChange={() => handleCheckboxChange(item.id)}
                    checked={selectedCluster.includes(item.id)}
                  />
                  <label style={{marginLeft: '5px', fontWeight: '600'}}>{item?.name}</label>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* <div className='row p-5'>
          <div className='mb-5 col-12'>
            <label className='form-label'>Remarks</label>
            <textarea
              rows={3}
              className='form-control form-control-solid'
              value={estimationDetails?.remarks}
              onChange={(e) =>
                setEstimationDetails({...estimationDetails, remarks: e.target.value})
              }
            ></textarea>
          </div>
        </div> */}
        <div className='row p-5'>
    <div className='mb-5 col-12'>
      <label className='form-label'>Upload Report</label>
      <input
        type='file'
        className='form-control form-control-solid'
        placeholder='--'
        multiple
        onChange={documentFileUploader}
      />
    </div>
    <div className='col-12'>
  <ul>
    {estimationDocuments.documentFiles.map((file, index) => (
      <li key={index}>
        <div>
          <span>{file.name}</span>
          <button
            type='button'
            onClick={() => removeDocument(index)}
            className='delete_butt_div col-4'
          >
            Remove
          </button>
        </div>
        <div>
          <label>Remark for {file.name}</label>
          <textarea
            rows={2}
            className='form-control'
            value={estimationDocuments.remarks[index] || ''}
            onChange={(e) =>
              setEstimationDocuments((prev) => {
                const updatedRemarks = [...prev.remarks];
                updatedRemarks[index] = e.target.value; // Update remark for this document
                return { ...prev, remarks: updatedRemarks };
              })
            }
          />
        </div>
      </li>
    ))}
  </ul>
</div>
  </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='success' onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
export default ReviewDocumentModal