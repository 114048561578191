import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Dropdown, DropdownButton, Modal, Button} from 'react-bootstrap'
import axios from 'axios'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'

const AllOther = () => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const [otherData, setOtherData] = useState<any>([])
  const [showPreview, setShowPreview] = useState(false)
  const [otherItem, setOtherItem] = useState<any>()

  const fetchData = async () => {
    try {
      // Make the API request
      const response = await axios.get(`${BASE_URL}/payment/other/anonymous/filter/`)

      // Extract and handle the data
      const result = response?.data?.results || []
      setOtherData(result)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAccept = async (id: string) => {
    try {
      // Payload with the data to be sent in the request
      const payload = {is_paid: true}

      // Make the API request
      const response = await axios.put(
        `${BASE_URL}/payment/update/anonymous/invoice/${id}/`,
        payload
      )

      toast.success('Payment Status Changed')
      fetchData()
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const handleReject = async (id: string) => {
    try {
      // Payload with the data to be sent in the request
      const payload = {is_paid: false}

      // Make the API request
      const response = await axios.put(
        `${BASE_URL}/payment/update/anonymous/invoice/${id}/`,
        payload
      )

      toast.success('Payment Status Changed')
      fetchData()
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      const pdf = new jsPDF('p', 'mm', 'a4')
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const margin = 10

      html2canvas(input, {
        scale: 2, // Increase the scale for higher resolution
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const imgProps = canvas.width / canvas.height

        // Calculate height based on aspect ratio
        const imgWidth = pdfWidth - margin * 2
        const imgHeight = imgWidth / imgProps

        let position = margin
        if (imgHeight <= pdfHeight - margin * 2) {
          // Content fits on one page
          pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight)
        } else {
          // Content exceeds one page, split into multiple pages
          let remainingHeight = canvas.height
          let pageHeight = (canvas.width * (pdfHeight - margin * 2)) / imgWidth
          while (remainingHeight > 0) {
            const section = canvas.getContext('2d')
            if (!section) {
              console.error('Failed to get 2D context from canvas.')
              break // Exit the loop if the context is not available
            }

            section.drawImage(
              canvas,
              0,
              canvas.height - remainingHeight,
              canvas.width,
              Math.min(pageHeight, remainingHeight),
              0,
              0,
              canvas.width,
              Math.min(pageHeight, remainingHeight)
            )

            const sectionData = section.canvas.toDataURL('image/png')
            pdf.addImage(sectionData, 'PNG', margin, margin, imgWidth, imgHeight)

            remainingHeight -= pageHeight
            if (remainingHeight > 0) pdf.addPage()
          }
        }

        pdf.save('Invoice.pdf')
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
    }
  }

  const showItem = (item: any) => {
    setOtherItem(item)
    setShowPreview(true)
  }
  return (
    <div>
      <div className='card-header pt-5'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x='17.0365'
                  y='15.1223'
                  width='8.15546'
                  height='2'
                  rx='1'
                  transform='rotate(45 17.0365 15.1223)'
                  fill='currentColor'
                />
                <path
                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <input
              id='le_lu_ri'
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              // onChange={handleSearchChange}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter') {
              //     handleSearchChange(e);
              //   }
              // }}
              placeholder='Search Invoice ID'
            />
          </div>
        </div>
        <div className='card-toolbar'>
          <div
            className='d-flex justify-content-end'
            style={{gap: '10px '}}
            data-kt-user-table-toolbar='base'
          >
            {/* <a id='le_lu_mai_1' className='btn btn-primary'>
              {' '}
              Export
            </a> */}
            <div style={{gap: '10px '}} data-kt-user-table-toolbar='base'>
              <Link to='/create-invoice' id='le_lu_mai_1' className='btn btn-primary'>
                {' '}
                Create Invoice
              </Link>
            </div>
            {/* <div className='d-flex justify-content-center flex-shrink-0'>
              <DropdownButton className='' id='dropdown-basic-button' title='Filter'>
                <Dropdown.Item
              
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
              
                >
                  Paid
                </Dropdown.Item>
                <Dropdown.Item
             
                >
                  Unpaid
                </Dropdown.Item>
                
              </DropdownButton>
            </div> */}
          </div>
          <div
            className='d-flex justify-content-end align-items-center d-none'
            data-kt-user-table-toolbar='selected'
          >
            <div className='fw-bold me-5'>
              <span className='me-2' data-kt-user-table-select='selected_count'></span>Selected
            </div>
            <button
              type='button'
              className='btn btn-danger'
              data-kt-user-table-select='delete_selected'
            >
              Delete Selected
            </button>
          </div>
        </div>
      </div>

      <div className='card-body pt-5'>
        <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
          <thead>
            <tr className='text-start  fw-bold  text-uppercase gs-0'>
              <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='1'
                    // checked={selectAll}

                    // onChange={handleSelectAll}
                  />
                </div>
              </th>
              <th className='min-w-125px'>Invoice To</th>

              <th className='min-w-125px'>Invoice#</th>
              <th className='min-w-125px'>Invoice Email</th>
              <th className='min-w-125px'>Invoice Date</th>
              <th className='min-w-125px'>Invoice</th>
              <th className='min-w-125px'>Status</th>
              <th className='text-center min-w-100px'>Actions</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-semibold'>
            {otherData.map((item) => {
              return (
                <tr key={item?.id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        id='le_lu_mai_2'
                      />
                    </div>
                  </td>
                  <td>
                    <Link
                      id='le_lu_mai_3'
                      to={`/`}
                      className='text-gray-800 text-hover-primary mb-1'
                    >
                      {item?.invoice_to}
                    </Link>
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      {item?.invoice_number}
                    </div>
                  </td>
                  <td>
                    <span className='text-gray-800 text-hover-primary mb-1'>
                      {item?.invoice_email}
                    </span>
                  </td>

                  <td className='text-gray-800 text-hover-primary mb-1'>{item?.issued_date}</td>
                  <td>
                    <div className='mb-1'>
                      <a onClick={() => showItem(item)}>
                        <img src='/media/doc.png' alt='' />
                      </a>
                      {/* {item?.resume ? (
                       
                      ) : null} */}
                    </div>
                  </td>

                  <td>
                    {item?.is_paid ? (
                      <div className='badge badge-light-primary fw-bold'>Paid</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Unpaid</div>
                    )}
                  </td>

                  <td>
                    <div className='d-flex justify-content-center flex-shrink-0'>
                      <DropdownButton className='' id='dropdown-basic-button' title='Action'>
                        <Dropdown.Item onClick={() => handleAccept(item?.id)}>Paid</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleReject(item?.id)}>Unpaid</Dropdown.Item>
                        {/* <Dropdown.Item href='#/action-3'>InProcess</Dropdown.Item> */}
                      </DropdownButton>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {/* <ul className='pagination'>
  <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
    <a   id="le_lu_mai_4" href='#' className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
      <i className='previous'></i>
    </a>
  </li>
  {totalPagesArray.map((page,index) => (
    <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
      <a  id={`le_lu_page_${index}`} href='#' className='page-link' onClick={() => handlePageChange(page)}>
        {page}
      </a>
    </li>
  ))}
  <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
    <a   id="le_lu_mai_5" href='#' className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
      <i className='next'></i>
    </a>
  </li>
</ul> */}
      </div>
      <Modal show={showPreview} onHide={() => setShowPreview(false)} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id='pdfContent'>
            <div className='' style={{border: '1px solid #333'}}>
              <div className='d-flex'>
                <div>
                  <img
                    alt='Logo'
                    src='/media/logos/uaf_logo.png'
                    className='p-1 app-sidebar-logo-default theme-light-show'
                    style={{
                      width: '250px',
                      height: '120px',
                      border: '1px solid #333',
                    }}
                  />
                </div>
                <div className='text-center' style={{border: '1px solid #333'}}>
                  <h2 style={{padding: '25px 10px 0 10px'}}>
                    UNITED ACCREDITATION FOUNDATION INC, 1060 LASKIN ROAD, SUITE 12B/13B, VIRGINIA
                    BEACH, VA 23451
                  </h2>
                </div>
              </div>
              <div className='row' style={{padding: '0 10px', fontSize: '18px'}}>
                <div className='col-2' style={{border: '1px solid #333'}}>
                  <div className='p-2'>Invoice Number</div>
                </div>
                <div className='col-2' style={{border: '1px solid #333'}}>
                  <div className='p-2'>{otherItem?.invoice_number}</div>
                </div>
                <div className='col-2' style={{border: '1px solid #333'}}>
                  <div className='p-2'>Dated</div>
                </div>
                <div className='col-2' style={{border: '1px solid #333'}}>
                  <div className='p-2'>{moment(otherItem?.issued_date).format('DD/MMM/YYYY')}</div>
                </div>
                <div className='col-2' style={{border: '1px solid #333'}}>
                  <div className='p-2'>Invoice By</div>
                </div>
                <div className='col-2' style={{border: '1px solid #333'}}>
                  <div className='p-2'>{otherItem?.issues_by}</div>
                </div>
              </div>
              <div style={{padding: '0 10px', border: '1px solid #333'}}>
                <div className='row text-center' style={{fontSize: '18px'}}>
                  <h2 style={{padding: '20px 0'}}>{otherItem?.invoice_to}</h2>
                  <h4>{otherItem?.invoice_email}</h4>
                  <h4>{otherItem?.invoice_location}</h4>
                </div>
              </div>
              <div style={{padding: '0 10px', border: '1px solid #333'}}>
                <div className='row text-center'>
                  <h2 style={{padding: '20px 0'}}>{otherItem?.invoice_subject}</h2>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                <table
                  className='table align-middle fs-6 gy-5 app_fee_form'
                  style={{border: '1px solid #000'}}
                >
                  <thead>
                    <tr className='text-start fw-bold text-uppercase gs-0'>
                      <th style={{border: '1px solid #000'}} className='min-w-20px'>
                        S.No
                      </th>
                      {Object.keys(otherItem?.table_data?.[0] || {}).map((col, colIndex) => (
                        <th
                          className='min-w-50px'
                          key={colIndex}
                          style={{border: '1px solid #000'}}
                        >
                          {col}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-semibold'>
                    {otherItem?.table_data?.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td
                          style={{
                            border: '1px solid #000',
                            padding: '10px',
                          }}
                        >
                          {rowIndex + 1}
                        </td>
                        {Object.keys(row).map((col, colIndex) => (
                          <td key={colIndex} style={{border: '1px solid #000'}}>
                            {row[col]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {otherItem?.remarks && (
                <div>
                  <p style={{fontSize: '18px'}}>{otherItem?.remarks}</p>
                </div>
              )}
            </div>

            {/* <div
              className='card-body px-9 py-5 align-items-center'
              style={{paddingLeft: '100px', paddingRight: '100px'}}
            >
              <div className='mt-2 d-flex justify-content-between align-items-center w-100'>
                <div className='p-2'>
                  <p className='m-0'>
                    <strong>Invoice To:</strong> {otherItem?.invoice_to}
                  </p>
                  <p className='m-0'>
                    <strong>Email:</strong> {otherItem?.invoice_email}
                  </p>
                  {otherItem?.invoice_location ? (
                    <p className='m-0'>
                      <strong>Location:</strong> {otherItem?.invoice_location}
                    </p>
                  ) : null}
                </div>
                <div className='p-4'>
                  <p className='m-0'>
                    <strong>Invoice Number:</strong> {otherItem?.invoice_number}
                  </p>
                  <p className='m-0'>
                    <strong>Issue Date:</strong>{' '}
                    {moment(otherItem?.issued_date).format('DD/MMM/YYYY')}
                  </p>
                  <p className='m-0'>
                    <strong>Issued By:</strong> {otherItem?.issues_by}
                  </p>
                </div>
              </div>

              <div className='mt-5 d-flex justify-content-center'>
                <table
                  className='table align-middle table-row-solid fs-6 gy-5 app_fee_form'
                  style={{border: '1px solid #000'}}
                >
                  <thead>
                    <tr className='text-start fw-bold text-uppercase gs-0'>
                      <th style={{border: '1px solid #000'}} className='min-w-20px'>
                        S.No
                      </th>
                    
                      {Object.keys(otherItem?.table_data?.[0] || {}).map((col, colIndex) => (
                        <th
                          className='min-w-50px'
                          key={colIndex}
                          style={{border: '1px solid #000'}}
                        >
                          {col}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-semibold'>
                  
                    {otherItem?.table_data?.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                     
                        <td style={{border: '1px solid #000'}}>{rowIndex + 1}</td>
                     
                        {Object.keys(row).map((col, colIndex) => (
                          <td key={colIndex} style={{border: '1px solid #000'}}>
                            {row[col]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {otherItem?.remarks ? (
                <div className='mt-5'>
                  <p style={{fontSize: '18px'}}>{otherItem?.remarks}</p>
                </div>
              ) : null}
            </div> */}
            <div className=' mb-5 mb-xl-10' style={{borderTop: '1px dotted #f2f2f2'}}>
              <div className=' cursor-pointer'>
                <div className=' m-0 d-flex justify-content-start align-items-center w-100'>
                  <span
                    className='fw-bolder'
                    style={{
                      // paddingLeft: "100px",
                      paddingRight: '100px',
                      fontSize: '16px',
                      margin: '14px 0px 0px 14px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Issued on behalf of Director:
                  </span>
                </div>
              </div>
              <div
                style={{paddingLeft: '12px', paddingRight: '100px'}}
                className='card-title m-0 justify-content-between align-items-center w-100 '
              >
                <h4 className='fw-bolder m-0 pb-2'>United Accreditation Foundation Inc (UAF)</h4>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
                  className='h-30px h-lg-60px py-2 app-sidebar-logo-default theme-light-show'
                />
                <p className='inline_master_head m-0'>1060 Laskin Road, </p>
                <p className='inline_master_head m-0'>Suite 12B/13B, </p>
                <p className='inline_master_head m-0'>Virginia Beach VA 23451, </p>
                <p className='inline_master_head m-0'>United States of America</p>
                <p className='inline_master_head m-0'>+1-757-228-5581</p>
                <p className='inline_master_head m-0'>www.uafaccreditation.org </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowPreview(false)}>
            Close
          </Button>

          <Button variant='info' onClick={generatePDF}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AllOther
