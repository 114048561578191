import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FaFolder, FaFileAlt } from "react-icons/fa";

const FolderHierarchy = ({ urlId }: { urlId: string }) => {
  const [hierarchy, setHierarchy] = useState<any>(null);
  const [currentFolder, setCurrentFolder] = useState<any>(null);
  const [breadcrumb, setBreadcrumb] = useState<any[]>([]);
  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchHierarchy = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/aws/file/structure/cab/?cab=${urlId}`
        );
        setHierarchy(response.data);
        setCurrentFolder(response.data); // Start at root
        setBreadcrumb([{ name: response.data.name, data: response.data }]); // Initialize breadcrumb
      } catch (err) {
        toast.error("Something went wrong!");
      }
    };
    fetchHierarchy();
  }, [BASE_URL, urlId]);

  const openFolder = (folder: any) => {
    setCurrentFolder(folder);
    setBreadcrumb((prev) => [...prev, { name: folder.name, data: folder }]);
  };

  const navigateBack = (index: number) => {
    const selectedBreadcrumb = breadcrumb[index];
    setCurrentFolder(selectedBreadcrumb.data);
    setBreadcrumb(breadcrumb.slice(0, index + 1));
  };

  const renderBreadcrumb = () =>
    breadcrumb
      .slice(1) // Skip the first item
      .map((crumb, index) => (
        <span
          key={index}
          onClick={() => navigateBack(index + 1)} // Adjust index for navigation
          style={{ cursor: "pointer", marginRight: "8px", fontSize: "14px" }}
        >
          {decodeURIComponent(crumb.name)} {index < breadcrumb.length - 2 && " / "} {/* Adjust for slice */}
        </span>
      ));

  const renderFiles = (files: any[]) =>
    files?.map((file, index) => (
      <li style={{ margin: "20px 10px" }} key={index}>
        <img
          src="https://uafaccreditation.s3.amazonaws.com/global/file/doc.png"
          style={{ marginRight: "5px" }}
        />
        <a href={file.redesign_url} target="_blank" rel="noopener noreferrer">
          {decodeURIComponent(file.file_name)}
        </a>
      </li>
    ));

  const renderFolders = (folders: any[]) =>
    folders?.map((child, index) => (
      <li
        key={index}
        onClick={() => openFolder(child)}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <FaFolder
          style={{
            marginRight: "10px",
            fontSize: "100px", // Ensures minimum size of 100px
            color: "gold", // Sets the icon color to golden
          }}
        />
        <span style={{ fontSize: "18px" }}>{decodeURIComponent(child.name)}</span>
      </li>
    ));

  return (
    <div>
      <div style={{ marginBottom: "16px" }}>{renderBreadcrumb()}</div>
      <ul className="file-folder" style={{ listStyle: "none", paddingLeft: "0" }}>
        {renderFolders(currentFolder?.children || [])}
        {currentFolder?.files && renderFiles(currentFolder.files)}
      </ul>
    </div>
  );
};

export default FolderHierarchy;
