import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import axios from 'axios'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import AccreditationScheduleSignature from './AccreditationScheduleSignature'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

interface ProgrammeData {
  id: string
  name: string
  description: string
}
const API_URL = process.env.REACT_APP_API_URL

const AccreditationSchedule = () => {
  const {id, sId} = useParams()
  const userId = id
  const s_id = sId
  const [signatureImageData, setSignatureImageData] = useState<any>() 
   const [signImage, setSignImage] = useState<any>()
  const [cabData, setCabData] = useState<any>()
  const [standardData, setStandardData] = useState<any[]>([])
  const [userData, setUserData] = useState<any>()
  const [userPrograms, setUserPrograms] = useState<any[]>([])
  const [programmes, setProgrammes] = useState<ProgrammeData[]>([])

  const [cabARCData, setCAbARCData] = useState<any>()
  console.log(userPrograms, 'userPrograms')
  const [cabLocationData, setCAbLocationData] = useState<any[]>([])
  const [cabARCDataCountry, setCAbARCDataCountry] = useState<any>([])
  const [schemeClusters, setSchemeClusters] = useState<any>([])
  console.log('cabARCDataCountry:', cabARCDataCountry)
  const [singleCab, setSingleCab] = useState<any>([])
  const [scheduleData, setScheduleData] = useState<any>([])
  const [countryList, setCountryList] = useState<any>()
  const [selectedPrograms, setSelectedPrograms] = useState<any>([])
  const [selectedSchemes, setSelectedSchemes] = useState<any>([])
  console.log('seletcedPrograms:', selectedPrograms)
  console.log('scheduleData:', scheduleData)
  console.log('selectedSchemes:', selectedSchemes)
  const [value, setValue] = useState({
    cab: '',
    cab_company: '',
    registration_number: '',
    main_key_location: '',
    issue_number: '',
    issue_date: new Date(),
    other_key_location: '',
    other_key_location_reg: '',
    approved_countries: {},
    other_location: '',
    remark: '',
    scheme_data: {},
    member_signature:null,
    member_name: '',
    signature_date: '',
    summary: '',
    cycle: 1,
    programme: '',
    accreditation_standards: '',
  })
  console.log(value,"valuevaluevalue");
  
  const [options, setOptions] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
  const handleSelectedProgram = async (selectedOptions) => {
    setSelectedPrograms(selectedOptions)
    setValue((prevState) => ({
      ...prevState,
      programme: selectedOptions?.value,
    }))
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/account/cab/programme/filter/?cab=${userId}&programme=${selectedOptions?.value}`
      )
      setSelectedSchemes(response.data)
    } catch (error) {
      console.error('Error fetching programme data:', error)
    }
  }
  const navigate = useNavigate()
  const handleChange = (event, schemeName, field, schemeId) => {
    const {value: inputValue} = event.target
    setValue((prevState) => ({
      ...prevState,
      scheme_data: {
        ...prevState.scheme_data,
        [schemeName]: {
          ...prevState.scheme_data[schemeName],
          [field]: inputValue,
          scheme_id: schemeId, // Add scheme_id by default
        },
      },
    }))
  }
  const handleCheckboxChange = (shortName, isEnabled) => {
    setValue((prevState) => ({
      ...prevState,
      scheme_data: {
        ...prevState.scheme_data,
        [shortName]: {
          ...prevState.scheme_data[shortName],
          enabled: isEnabled,
        },
      },
    }))
  }
  useEffect(() => {
    const fetchProgrammes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/global/programme/list/`)
        setProgrammes(response.data)
      } catch (error) {
        console.error('Error fetching programme data:', error)
      }
    }
    fetchProgrammes()
  }, [])
  const getUserDetails = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/account/patch/user/${userId}/`)
      .then((response) => {
        const result = response.data
        setUserData(result)
        setUserPrograms(result?.programme_names)
      })
      .catch((error) => {
        // Handle errors
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    getUserDetails()
  }, [])
  const handleCountryChange = (selectedOptions) => {
    console.log('Selected Options:', selectedOptions)
    setCAbARCDataCountry(selectedOptions || [])
  }
  // const handleSignatureSave = (dataUrl: string) => {
  //   setSignatureImageData(dataUrl)
  //   setValue((prevState) => ({
  //     ...prevState,
  //     member_signature: dataUrl,
  //   }))
  // }
  useEffect(() => {
    if (cabData) {
      const getCABUserDetails = async () => {
        try {
          const response = await axios.get(`${API_URL}/account/patch/user/${cabData?.id}/`)
          setSingleCab(response.data)
        } catch (error) {
          console.error('API Error:', error)
          // throw error;
        }
      }
      getCABUserDetails()
    }
  }, [cabData])
  useEffect(() => {
    if (s_id) {
      const getScheduleDetails = async () => {
        try {
          const response = await axios.get(`${API_URL}/arc/schedule/data/update/${s_id}/`)
          setScheduleData(response.data)
          setCAbARCDataCountry(
            response?.data?.country_data.map((country) => ({
              value: country?.id,
              label: country?.name,
            }))
          )
          // setCAbARCDataCountry(response.data?.country_data)
        } catch (error) {
          console.error('API Error:', error)
          // throw error;
        }
      }
      getScheduleDetails()
    }
  }, [s_id])
  useEffect(() => {
    setValue((prevValue) => ({
      ...prevValue,
      scheme_data: scheduleData?.scheme_data || {},
      cycle: scheduleData?.cycle, // Default to an empty object if undefined
      accreditation_standards: scheduleData?.accreditation_standards,
      remark: scheduleData?.remark,
    }))
  }, [scheduleData])
  useEffect(() => {
    if (cabData) {
      setValue((prevValue) => ({
        ...prevValue,
        cab: cabData?.id,
        cab_company: cabData?.company,
        registration_number: cabData?.license_number,
        main_key_location: cabLocationData[0]?.id,
        other_key_location: cabARCData?.recommended_location,
        approved_countries: cabARCDataCountry?.map((i) => i?.value),
        other_location: cabARCData?.other_location,
        member_signature:signatureImageData
       
      }))
    }
  }, [cabData, cabARCData, cabARCDataCountry, signatureImageData])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/arc/management/schedule/data/get/?cab=${id}`)
        setCabData(response?.data?.cab_data)
        setCAbARCData(response?.data?.arc_data[0])
        //    setCAbARCDataCountry(response?.data?.arc_data[0]?.country_data)
        setCAbARCDataCountry(
          response?.data?.arc_data[0]?.country_data.map((country) => ({
            value: country?.id,
            label: country?.name,
          }))
        )
        setCAbLocationData(response?.data?.key_location)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }
    fetchData()
  }, [id])
  const fetchCountryData = async () => {
    try {
      const response = await axios.get(`${API_URL}/certificate/list/country/`)
      setCountryList(response.data)
    } catch (error) {
      ////console.log(error)
    }
  }
  useEffect(() => {
    fetchCountryData()
  }, [])
  useEffect(() => {
    const fetchStandardData = async () => {
      try {
        const responses = await Promise.all(
          cabData?.scheme_names.map((scheme) =>
            axios.get(`${API_URL}/global/standard/filter/?scheme=${scheme?.id}`)
          )
        )
        const data = responses.map((response) => response.data)
        setStandardData(data)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }

    fetchStandardData()
  }, [cabData])

  useEffect(() => {
    const getSchemeClusterName = async () => {
      try {
        const response = await axios.get(`${API_URL}/account/cab/scheme/filter/?id=${userId}`)
        setSchemeClusters(response.data[0]?.scheme_with_cluster)
      } catch (error) {
        console.error('API Error:', error)
        // throw error;
      }
    }
    getSchemeClusterName()
  }, [])
  // const handleSubmit = async (event: React.FormEvent) => {
  //   event.preventDefault()
  //   console.log(value, 'Printed Value.......!')
  //   const {issue_number, issue_date} = value
  //   if (!issue_number || !issue_date) {
  //     toast.error(' Please fill in Required the details.')
  //     return
  //   }

  //   try {
  //     const response = await axios.post(`${API_URL}/arc/schedule/data/create/`, value)
  //     if (response.status === 200 || response.status === 201) {
  //       toast.success('Schedule Created Successfully.')
  //       navigate(`/crafted/pages/profile/${id}/schedule`)
  //     } else {
  //       toast.error('Failed to submit application. Please try again.')
  //     }
  //   } catch (error) {
  //     toast.error('Something went wrong, please try again')
  //   }
  // }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log(value, 'Printed Value.......!');

    const { issue_number, issue_date } = value;
    if (!issue_number || !issue_date) {
        toast.error('Please fill in the required details.');
        return;
    }

    try {
        // Create a FormData object
        const formData = new FormData();
        Object.entries(value).forEach(([key, val]) => {
            if (val !== null && val !== undefined) {
                if (key === 'member_signature' && val instanceof File) {
                    // Append the file directly if it is a File object
                    formData.append(key, val);
                } else if (key === 'approved_countries' && Array.isArray(val)) {
                    // Append each UUID separately for arrays of UUIDs
                    val.forEach((item) => formData.append(`${key}[]`, item));
                } else if (typeof val === 'object') {
                    // Serialize objects
                    formData.append(key, JSON.stringify(val));
                } else {
                    // Convert other types to strings and append
                    formData.append(key, String(val));
                }
            }
        });

        // Send the form data via POST request
        const response = await axios.post(`${API_URL}/arc/schedule/data/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200 || response.status === 201) {
            toast.success('Schedule Created Successfully.');
            navigate(`/crafted/pages/profile/${id}/schedule`);
        } else {
            toast.error('Failed to submit application. Please try again.');
        }
    } catch (error) {
        toast.error('Something went wrong, please try again.');
    }
};

  const addMoreOptions = () => {
    const newOption = options.length + 1 // Generate the next number
    setOptions([...options, newOption]) // Append it to the existing options array
  }
  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData()
    setValue((prevValue) => ({
      ...prevValue,
      accreditation_standards: data,
    }))
  }
  const handleEditRemarks = (event: any, editor: any) => {
    const data = editor.getData()
    setValue((prevValue) => ({
      ...prevValue,
      remark: data,
    }))
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSignImage(imageUrl);
      setSignatureImageData(file)
      setValue((prevValue) => ({
        ...prevValue,
        member_signature: file,
       
    }))
  }}
  return (
    <div>
      <h2>Accreditation Schedule</h2>
      <form onSubmit={handleSubmit}>
        <div className='py-lg-10 px-lg-10'>
          <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-body p-9'>
              <div className='row'>
                {/* <div className='mb-5 col-lg-6 '>
                  <label className='required form-label'>Select Cycle</label>
                  <select
                    className='form-control form-control-solid'
                    name='cycle'
                    value={value.cycle}
                    onChange={(e) => setValue({...value, cycle: Number(e.target.value) })} 
                    disabled={!!s_id}
                  >
                    <option value='' disabled>
                      Select a Cycle
                    </option>
                    {options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <button
                    type='button'
                    className='btn btn-primary mt-2'
                    onClick={addMoreOptions}
                    disabled={!!s_id}
                  >
                    Add More Cycle
                  </button>
                </div> */}
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>CAB Name</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter A Unique Issue Number '
                    name='cab'
                    onChange={(e) => setValue({...value, cab_company: e?.target?.value})}
                    value={value?.cab_company}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Select Program</label>
                  <Select
                    className='react-select-styled react-select-solid'
                    classNamePrefix='react-select'
                    options={userPrograms?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))}
                    placeholder='Select Program'
                    onChange={handleSelectedProgram}
                    value={selectedPrograms}
                  />
                </div>
                <div className='mb-5 col-lg-12'>
                  <label className='required form-label'>Accredited to ISO/IEC 17021-1:2015</label>
                  <div className='row'>
                    {selectedSchemes ? (
                      <table className='table table-responsive table-bordered'>
                        <thead>
                          <tr>
                            <th>Select</th>
                            <th>Scheme(s)</th>
                            <th className='required'>Accreditation Number</th>
                            <th className='required'>Accreditation Valid From</th>
                            <th className='required'>Accreditation Valid Up to</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedSchemes?.scheme_res?.map((scheme) => (
                            <tr key={scheme?.scheme_id}>
                              <td>
                                <input
                                  type='checkbox'
                                  checked={
                                    value?.scheme_data[scheme?.scheme_short_name]?.enabled || false
                                  }
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      scheme?.scheme_short_name,
                                      e.target.checked
                                    )
                                  }}
                                />
                              </td>
                              <td>{scheme?.scheme_short_name}</td>
                              <td>
                                <input
                                  type='text'
                                  className='form-control form-control-solid mb-2'
                                  placeholder='Enter Accreditation Number'
                                  value={
                                    value?.scheme_data[scheme?.scheme_short_name]?.issue_number ||
                                    ''
                                  }
                                  onChange={(e) =>
                                    value?.scheme_data[scheme?.scheme_short_name]?.enabled &&
                                    handleChange(
                                      e,
                                      scheme?.scheme_short_name,
                                      'issue_number',
                                      scheme?.scheme_id
                                    )
                                  }
                                  disabled={!value?.scheme_data[scheme?.scheme_short_name]?.enabled}
                                />
                              </td>
                              <td>
                                <input
                                  type='date'
                                  className='form-control form-control-solid mb-2'
                                  placeholder='Enter Valid Date'
                                  value={
                                    value?.scheme_data[scheme?.scheme_short_name]?.valid_date || ''
                                  }
                                  onChange={(e) =>
                                    value?.scheme_data[scheme?.scheme_short_name]?.enabled &&
                                    handleChange(
                                      e,
                                      scheme?.scheme_short_name,
                                      'valid_date',
                                      scheme?.scheme_id
                                    )
                                  }
                                  disabled={!value?.scheme_data[scheme?.scheme_short_name]?.enabled}
                                />
                              </td>
                              <td>
                                <input
                                  type='date'
                                  className='form-control form-control-solid'
                                  placeholder='Enter Expiry Date'
                                  value={
                                    value?.scheme_data[scheme?.scheme_short_name]?.expiry_date || ''
                                  }
                                  onChange={(e) =>
                                    value?.scheme_data[scheme?.scheme_short_name]?.enabled &&
                                    handleChange(
                                      e,
                                      scheme?.scheme_short_name,
                                      'expiry_date',
                                      scheme?.scheme_id
                                    )
                                  }
                                  disabled={!value?.scheme_data[scheme?.scheme_short_name]?.enabled}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : null}
                    {/* {cabData?.scheme_names.map((scheme) => (
                      <div key={scheme?.id} className='mb-5'>
                        <label>{scheme?.short_name}</label>
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='Please Enter Accreditation Number'
                          value={value?.scheme_data[scheme?.short_name] || ''} // Display the stored value
                          onChange={(e) => handleChange(e, scheme?.short_name)}
                        />
                      </div>
                    ))} */}
                  </div>
                </div>

                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Issue No</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter A Unique Issue Number '
                    name='ncr_number'
                    onChange={(e) => setValue({...value, issue_number: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Issue Date</label>
                  <br />

                  <ReactDatePicker
                    selected={value.issue_date}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={(date) => setValue({...value, issue_date: date})}
                    dropdownMode='select'
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>
                    Main Key Location Address and Contact Details
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Please Enter A Unique Standard/ClauseNo'
                    value={cabData?.address}
                    name='standard_clause'
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Legal Registration details</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    name='cab_representative'
                    value={value?.registration_number}
                    onChange={(e) => setValue({...value, registration_number: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Other Key Location(s)</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={value?.other_key_location}
                    onChange={(e) => setValue({...value, other_key_location: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>
                    Legal Registration Details of Other Key Location
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={value?.other_key_location_reg}
                    onChange={(e) => setValue({...value, other_key_location_reg: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Other Location(s)</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={value?.other_location}
                    onChange={(e) => setValue({...value, other_location: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>
                    Countries where UAF accredited certificates can be Issued (Subject to Compliance
                    with UAF Policy For CAB Operations in Sanctioned Countries)
                  </label>

                  <Select
                    isMulti
                    className='react-select-styled react-select-solid'
                    classNamePrefix='react-select'
                    options={countryList?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))}
                    placeholder='Select Country'
                    onChange={handleCountryChange}
                    value={
                      Array.isArray(cabARCDataCountry)
                        ? cabARCDataCountry.map((selectedCountry) => ({
                            value: selectedCountry?.value,
                            label: selectedCountry?.label,
                          }))
                        : []
                    }
                  />
                  {/* <Select
                    isMulti
                    className='react-select-styled react-select-solid '
                    name='User'
                    classNamePrefix='react-select'
                    isDisabled
                    value={cabARCDataCountry?.map((i) => ({label: i?.name, value: i?.id}))}
                  /> */}
                </div>
                <div className='mb-5'>
                  <label className=' requiredform-label'>Accreditation Standards</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={value?.accreditation_standards || ''}
                    onChange={handleEditorChange}
                  />
                  {/* <textarea
                    className='  form-control form-control-solid'
                    rows={5}
                    placeholder='Enter Standards'
                    value={value?.summary}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        accreditation_standards: e.target.value,
                      })
                    }
                  /> */}
                </div>
                <div className='mb-5'>
                  <label className=' requiredform-label'>Summary</label>
                  <textarea
                    className='  form-control form-control-solid'
                    rows={5}
                    placeholder='Enter Summary'
                    value={value?.summary}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        summary: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>Remarks</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={value?.remark || ''}
                    onChange={handleEditRemarks}
                  />
                </div>

                {/* <div className='mb-5 mt-5 text-center'>
                  <h2 className=''>Certification Standards: </h2>
                  <ul style={{listStyleType: 'none'}}>
                    {standardData?.map((standard) => (
                      <li className=' form-label'>
                        {standard[0]?.name}--{standard[0]?.scheme_name}--Requirements
                      </li>
                    ))}
                  </ul>
                </div> */}

                {/* <div className='mb-10 mt-10 text-center'>
                  <h2 className='mb-5'>SCOPE OF ACCREDITATION: </h2>

                  {schemeClusters?.map((scheme) => (
                    <table
                      className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                      id='kt_table_users'
                      style={{border: '1px solid #d6d6d6'}}
                    >
                      <thead>
                        <tr className='text-center  fw-bold  text-uppercase gs-0'>
                          <th className=' pe-2 fs-7' style={{background: '#f2f2f2'}}>
                            {' '}
                            {scheme?.scheme_name}
                          </th>
                        </tr>
                      </thead>

                      <tbody className='text-gray-600 fw-semibold '>
                        {scheme?.clusters?.map((item) => (
                          <tr>
                            <td>
                              <div className='text-gray-800 mb-1'>{item?.name}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ))}
                </div> */}
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Member Name</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Member Name '
                    name='member_name'
                    onChange={(e) => setValue({...value, member_name: e?.target?.value})}
                    value={value?.member_name}
                  />
                </div>
                {/* <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Date </label>
                  <br />
                  <ReactDatePicker
                    selected={value?.signature_date}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={(date) => setValue({...value, signature_date: date})}
                    dropdownMode='select'
                  />
                </div> */}
                <div className='mb-5 col-lg-6'>
                  <label className='col-lg-4 fw-bold text-muted'>Signature</label>
                  <input type='file' accept='image/*' onChange={handleImageChange} />
                  {/* <AccreditationScheduleSignature onSave={handleSignatureSave} /> */}
                </div>
              </div>
            </div>
            <div className='mb-5 px-10'>
              <button type='submit' className='btn btn-primary'>
                Save Accreditation Schedule
                <i className='fa fa-angle-double-right' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default AccreditationSchedule
