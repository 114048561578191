import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Select, { SingleValue } from 'react-select';
const API_URL = process.env.REACT_APP_API_URL
const AssessmentInvoice = () => {
    interface AssessmentObject {
        [key: string]: string;
    }
    interface SelectOption {
        value: Assessment;
        label: string;
    }
    interface Assessment {
        assessment_number: string;
        // Add other properties of your assessment data here
        [key: string]: any;
    }
    interface FormData {
        cab: string
        assessment?: any
        sub_total_pre_taxes: any
        taxes_if_any: any
        grand_total: any
        net_payable_amount: any
        net_amount_invoice: any
        assessor_planner: any
        any_other_charges: any
        remarks: any
      }
      const initialFormData: FormData = {
        cab: '',
        assessment: [],
        sub_total_pre_taxes: '',
        taxes_if_any: '',
        grand_total: '',
        net_payable_amount: '',
        net_amount_invoice: '',
        assessor_planner: '',
        any_other_charges: '',
        remarks: '',
      }
    interface AccData {
        Ac_with_institution_bic: string,
        account_name: string,
        account_number: string,
        ach_routing_no: string,
        bank_address: string,
        id: string,
        wire_routing_no: string
    }
    const initialAccData: AccData = {
        Ac_with_institution_bic: "",
        account_name: "",
        account_number: "",
        ach_routing_no: "",
        bank_address: "",
        id: "",
        wire_routing_no: ""
    };

    const [formData, setFormData] = useState<FormData>(initialFormData);
    console.log(formData, "formData");

    const [userData, setUserData] = useState<any>();
    const [accountDetails, setAccountDetails] = useState<AccData>(initialAccData);
    const [assessmentData, setAssessmentData] = useState<any>([])
    const [selectedAssessment, setSelectedAssessment] = useState<any>([]);
    const [assessorDayFee, setAssessorDayFee] = useState<any>('')
    const [dataWithScheme, setDataWithScheme] = useState<any>([])

    console.log(selectedAssessment, "selectedAssessment");
    // useEffect(() => {
    //     const assessmentData = selectedAssessment?.map((item) => ({
    //         "assessment": item?.value,
    //         "remark": "",
    //         "total_assessor_day": "",
    //         "assessor_day_fee": "",
    //         "total_amount_usd": "",
    //     }))
    //     setAssessment(assessmentData)
    // }, [selectedAssessment])
    const handleSelectChange = (selectedOption: SingleValue<SelectOption>) => {
        if (selectedOption) {
            setSelectedAssessment(selectedOption.value); // Set the selected object as state
            console.log('Selected Assessment:', selectedOption.value); // Log the selected object
        }
    };
    const fetchAssessmentSchemeData = async () => {
        try {
            const response = await axios.get(
                `${API_URL}/cab/get/assessor/planner/scheme/${selectedAssessment?.id}/data`
            );

            const schemeData = response.data;
            return schemeData;
        } catch (error) {
            console.error('API Error:', error);
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const schemeData = await fetchAssessmentSchemeData();
            const updatedDataWithScheme = { ...selectedAssessment, schemeData };
            setSelectedAssessment(updatedDataWithScheme);
        };
        if (selectedAssessment) {
            fetchData();
        }
    }, [selectedAssessment?.id]);

    const getBankList = () => {
        axios
            .get(`${API_URL}/cab/bank/list/`)
            .then((response) => {
                const result = response.data?.[0]
                const data = {
                    Ac_with_institution_bic: result?.Ac_with_institution_bic,
                    account_name: result?.account_name,
                    account_number: result?.account_number,
                    ach_routing_no: result?.ach_routing_no,
                    bank_address: result?.bank_address,
                    id: result?.id,
                    wire_routing_no: result?.wire_routing_no
                }
                setAccountDetails(data)
            })
            .catch((error) => {
                console.error('API Error:', error)
            })
    }
    const getAssessmentPlanner = () => {
        axios
            .get(`${API_URL}/cab/get/assessor/planner/`)
            .then((response) => {

                const result = response.data

                setAssessmentData(result);
            })
            .catch((error) => {
                console.error('API Error:', error)
            })
    }
    const validateForm = () => {
        if (!assessorDayFee || assessorDayFee.trim() === '') {
          toast.error('Please Enter assessor day fee.')
          return false
        }
    
        if (!formData.taxes_if_any || formData.taxes_if_any.trim() === '') {
          toast.error('Please Enter Other taxes.')
          return false
        }
        if (!formData.grand_total || formData.grand_total.trim() === '') {
          toast.error('Please Enter Grand Total.')
          return false
        }
        if (!formData.net_payable_amount || formData.net_payable_amount.trim() === '') {
          toast.error('Please Enter Net Payable on account of this Invoice.')
          return false
        }
        if (!formData.net_amount_invoice || formData.net_amount_invoice.trim() === '') {
          toast.error('Please Enter Net Amount of Invoice.')
          return false
        }
        if (!accountDetails.account_name || accountDetails.account_name.trim() === '') {
          toast.error('Please Enter Account Name.')
          return false
        }
        if (!accountDetails.bank_address || accountDetails.bank_address.trim() === '') {
          toast.error('Please Enter Bank Address.')
          return false
        }
        if (!accountDetails.account_number || accountDetails.account_number.trim() === '') {
          toast.error('Please Enter Account Number.')
          return false
        }
        if (!accountDetails.ach_routing_no || accountDetails.ach_routing_no.trim() === '') {
          toast.error('Please Enter ACH Routing Number.')
          return false
        }
        if (!accountDetails.wire_routing_no || accountDetails.wire_routing_no.trim() === '') {
          toast.error('Please Enter Wire Routing Number.')
          return false
        }
        if (
          !accountDetails.Ac_with_institution_bic ||
          accountDetails.Ac_with_institution_bic.trim() === ''
        ) {
          toast.error('Please Enter A/c with Institution BIC.')
          return false
        }
        return true
      }
    useEffect(() => {
        getAssessmentPlanner();
        getBankList();

    }, [])
    const GenerateMasterFormLink = (id) => {
        axios
            .get(`${API_URL}/cab/send/estimation/assessment/expire/link/${selectedAssessment?.cab}/${id}/`)
            .then((response) => {
                const result = response.data;
                console.log(result, 'result');
            })
            .catch((error) => {
                console.error('API Error:', error)
            });
    }
    const handleEstimationData = async () => {
        if (!validateForm()) return
        const data = formData
        data.cab = selectedAssessment?.cab
        data.sub_total_pre_taxes =
        selectedAssessment?.schemeData
            ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) * assessorDayFee
        data.any_other_charges = formData?.any_other_charges
        data.remarks = formData?.remarks
        data.taxes_if_any = formData?.taxes_if_any
        data.grand_total = formData?.grand_total
        data.net_payable_amount = formData?.net_payable_amount
        data.assessor_planner = selectedAssessment?.id
        data.net_amount_invoice = formData?.net_amount_invoice
        data.assessment = [
          {
            assessment: selectedAssessment?.assessment_type,
            remark: selectedAssessment?.remark,
            total_assessor_day: selectedAssessment?.schemeData
              ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
              ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0),
            assessor_day_fee: assessorDayFee,
            total_amount_usd:
            selectedAssessment?.schemeData
                ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
                ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) * assessorDayFee,
          },
        ]

        try {
            const response = await axios.post(`${API_URL}/cab/send/assessment/estimation/`, data);
            toast.success("Assessment Invoice sent successfully.")
            GenerateMasterFormLink(response?.data?.id)
            setFormData(initialFormData)
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="container">
            <div className='row '>
                <div className="mb-5 col-6">
                    <label className="required form-label fw-bolder">Select Assessment Number</label>
                    <Select
                        id="select_user"
                        name="User"
                        options={assessmentData.map((item) => ({
                            value: item, // Store the full assessment object as value
                            label: item.assessment_number // Display the assessment number as label
                        }))}
                        className='react-select-styled'
                        classNamePrefix='react-select'
                        onChange={handleSelectChange}
                    />
                </div>
                <div className="mb-5 col-6 px-10 py-5 ">
                    <h4 className='fw-bolder mb-2'>CAB: <span className="inline_master_head">{selectedAssessment?.cab_company}</span></h4>
                    <h4 className='fw-bolder m-0'>Dated: <span className="inline_master_head">{selectedAssessment?.create_at && moment(selectedAssessment.create_at).format("DD/MMM/YYYY")}</span></h4>
                </div>
            </div>
            <table className='table table-responsive'>
              <thead>
                <tr>
                  <th>	Assessment Stage</th>
                  <th>Assessment Type</th>
                  <th>Schemes</th>
                  <th>Assessor Day(s)</th>
                </tr>
              </thead>
            </table>
            <div className=' card mb-5 mb-xl-10' >
                <div className='card-header cursor-pointer app_fee_card'>
                    <div className='card-title m-0 d-flex flex-column justify-content-center align-items-center w-100'>
                        <img alt="Logo" src="/media/logos/uaf_logo.png" className=" p-2 h-75px app-sidebar-logo-default" />
                        <h4 className='fw-bolder m-0'>Assessment Invoice Fee</h4>
                        {/* <h4 className='fw-bolder m-0'>Dated: <span className="inline_master_head">{moment(new Date()).format("DD/MMM/YYYY")}</span></h4> */}
                    </div>
                    {/* <div className='card-title m-0 d-flex justify-content-start align-items-center w-100'>
                <span className="fs-18 pt-5"><strong>CAB Organisation: </strong>{userData?.company} </span>
            </div>
            <div className='card-title m-0 d-flex justify-content-start align-items-center w-100'>
                <h4 className='fw-bolder m-0'>CAB Address: <span className="inline_master_head">{userData?.country_name}</span></h4>
            </div> */}
                </div>
                <div className='card-body px-9 py-5'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5 app_fee_form'>
                        <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                <th className='max-w-100px'></th>
                                <th className='max-w-100px'>Total Assessor days</th>
                                <th className='max-w-100px'>Assessor day Fee ($)</th>
                                <th className='max-w-100px'>Total Amount USD($)</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>


                            <tr >
                                <td>
                                    <div className='d-flex gap-5'>
                                        <span className='min-w-150px d-flex align-items-center'>
                                            <strong>{selectedAssessment?.assessment_name}</strong>
                                        </span>

                                    </div>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        className='form-control form-control-solid'
                                        placeholder='--'
                                        value={selectedAssessment?.schemeData
                                            ?.filter(
                                                (i) =>
                                                    i?.assessment_duration !== undefined &&
                                                    i?.assessment_duration !== null
                                            )
                                            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0)}
                                        readOnly
                                    />
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        className='form-control form-control-solid'
                                        placeholder='--'
                                        value={assessorDayFee}
                                        onChange={(e) => setAssessorDayFee(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        className='form-control form-control-solid'
                                        placeholder='--'
                                        value={
                                            selectedAssessment?.schemeData
                                                ?.filter(
                                                    (i) =>
                                                        i?.assessment_duration !== undefined &&
                                                        i?.assessment_duration !== null
                                                )
                                                ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) *
                                            assessorDayFee
                                        }
                                    />
                                </td>
                            </tr>



                            <tr>
                    <td>Sub Total Pre Taxes</td>
                    <td>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={
                            selectedAssessment?.schemeData
                            ?.filter(
                              (i) =>
                                i?.assessment_duration !== undefined &&
                                i?.assessment_duration !== null
                            )
                            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) *
                          assessorDayFee
                        }
                      />
                    </td>
                  </tr>
                            <tr>
                    <td>Any Other Charges</td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        onChange={(e) =>
                          setFormData({...formData, any_other_charges: e?.target?.value})
                        }
                      />
                    </td>
                  </tr>
                  {/* <tr>
                                        <td>Remarks</td>
                                        <td><input type="text" className="form-control form-control-solid" placeholder="--" onChange={(e) => setFormData({ ...formData, remark: e?.target?.value })} /></td>
                                    </tr> */}
                  <tr>
                    <td>Taxes, if any</td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={formData?.taxes_if_any}
                        onChange={(e) => setFormData({...formData, taxes_if_any: e?.target?.value})}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Grand Total </td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={formData?.grand_total}
                        onChange={(e) => setFormData({...formData, grand_total: e?.target?.value})}
                      />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Net Payable on account of this Invoice </td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={formData?.net_payable_amount}
                        onChange={(e) =>
                          setFormData({...formData, net_payable_amount: e?.target?.value})
                        }
                      />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Net Amount of Invoice USD ($) </td>
                    <td>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={formData?.net_amount_invoice}
                        onChange={(e) =>
                          setFormData({...formData, net_amount_invoice: e?.target?.value})
                        }
                      />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0 pb-2'>Issued on behalf of Director</h4>
              <p className="inline_master_head m-0">United Accreditation Foundation Inc (UAF) </p>
              <img
                alt='Logo'
                src={('/media/logos/uaf_logo.png')}
                className='h-30px h-lg-60px py-2 app-sidebar-logo-default theme-light-show'
              />
               <p className="inline_master_head m-0">1060 Laskin Road, </p>
              <p className="inline_master_head m-0">Suite 12B/13B,  </p>
              <p className="inline_master_head m-0">Virginia Beach VA 23451,  </p>
              <p className="inline_master_head m-0">United States of America</p>
              <p className="inline_master_head m-0">+1-757-228-5581</p>
              <p className="inline_master_head m-0">www.uafaccreditation.org </p>
            </div>
            <div className="d-flex gap-5">
                <button id="create_assessment_inovice" className='thm-btn' onClick={handleEstimationData}>
                    Send
                </button>
            </div>
        </div >
    )
}

export default AssessmentInvoice