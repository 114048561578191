import React from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'

import { VerifiedAssessorDoc } from './components/VerifiedAssessorDoc'
import { VerifiedAssessorSetting } from './components/VerifiedAssessorSetting'

import VerifiedAssessorHeader from './components/VerifiedAssessorHeader'
import VerifiedAssessorOverview from './components/VerifiedAssessorOverview'
import VerifiedAssessorDocDetails from './components/VerifiedAssessorDocDetails'
import { VerifiedAssessorAssessment } from './components/VerifiedAssessorAssessment'
import {VerifiedAssessorARC} from './components/VerifiedAssessorARC'
import VerifiedAssessorNC from './components/VerifiedAssessorNC'
import VerifiedAssessorPerformance from './components/VerifiedAssessorPerformance'
import { VerifiedAssessorsFiles } from './components/VerifiedAssessorsFiles'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Assessor',
    path: '/assessor/verified/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const VerifiedAssessorPage: React.FC = () => {

  const Id = useParams()
  console.log(Id,"printed IDIDIDI");
  const userId = JSON.stringify(Id)


  return(
    <div id="hello_1">
    <Routes>
    <Route
      element={
        <>
          <VerifiedAssessorHeader userId={userId}  />
          <Outlet />
        </>
      }
    >
      <Route
        path=':id/overview'

        element={
          <>
            {/* <PageTitle breadcrumbs={accountBreadCrumbs}>Verified Assessor</PageTitle> */}
            <VerifiedAssessorOverview userId={userId} />
          </>

        }
      />
      <Route
        path=':id/documents'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
            <VerifiedAssessorDoc userId={userId} />
          </>
        }
      />
       <Route
        path=':id/documents/details/:itemId'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
            <VerifiedAssessorDocDetails />
          </>
        }
      />
       <Route
        path=':id/files'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Files</PageTitle>
            <VerifiedAssessorsFiles userId={userId} />
          </>
        }
      />
       <Route
        path=':id/assessment'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Assessments</PageTitle>
            <VerifiedAssessorAssessment userId={userId} />
          </>
        }
      />
       <Route
        path=':id/arc'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>ARC</PageTitle>
            <VerifiedAssessorARC userId={userId} />
          </>
        }
      />
      <Route
        path=':id/nc'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>NC</PageTitle>
            <VerifiedAssessorNC userId={userId} />
          </>
        }
      />
       <Route
        path=':id/performance'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Performance</PageTitle>
            <VerifiedAssessorPerformance userId={userId} />
          </>
        }
      />
    {/*  <Route
        path='/documents'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
            <Documents />
          </>
        }
      />
      <Route
        path=':id/connections'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Assigned Team</PageTitle>
            <Connections />
          </>
        }
      /> */}
      <Route index element={<Navigate to='/accessor/verified/overview' />} />
    </Route>
  </Routes>
 </div>
  )
}


export default VerifiedAssessorPage
