/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { KTIcon,toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import axios from 'axios'

import AddToGroup from '../team-mangement/components/settings/cards/AddToGroup'
import { toast } from 'react-toastify'
import { Button, Modal } from 'react-bootstrap'


const BASE_URL = process.env.REACT_APP_API_URL



const GlobalProgramme = () => {
    const [value, setValue] = useState<any[]>([]);
    const [addNewProgramme, setAddNewProgramme] = useState({ id: "", name: '' });
    const [showEditRole, setShowEditRole] = useState<any>()
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/global/programme/list/`
        );
        setValue(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      fetchData()
    }, []);
    const Validation = () => {
      if (!addNewProgramme?.name) {
        toast.error("Please Enter Programme First")
        return false;
      }
      return true;
    }
    const AddProgramme = async () => {
      if (!Validation()) return;
      try {
        const response = await axios.post(`${BASE_URL}/global/programme/post/`, addNewProgramme);
        fetchData();
        setAddNewProgramme({ id: "", name: '' });
        toast.success("Programme created Successfully")
      } catch (error) {
        console.log(error);
      }
    };

  
    const handleEditProgramme = async (id, name) => {
      try {
        const response = await axios.put(`${BASE_URL}/global/programme/update/${id}/`, { name });
        if (response.status === 200) {
          fetchData();
          toast.success("Programme Updated Successfully");
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Updating Role");
      }
    };
  return (
    <div id="text_context">

      <div className='row g-5 g-xl-8'>

        <div className='col-xl-12'>
    <div className='card card-xl-stretch mb-xl-8'>
   
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Programme</span>
        </h3>
        <div className='me-0'>
          <button
            className='btn btn-sm btn-primary '
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            + Add Programme
           
          </button>
          <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='mb-10'>
                <label className='form-label fw-bold'>Programme:</label>
                <input type="text"
                  className="form-control form-control-solid"
                  placeholder="Enter Programme Name"
                  value={addNewProgramme.name}
                  onChange={(e) => setAddNewProgramme({ ...addNewProgramme, name: e?.target?.value })}
                />
              </div>
              <div className='d-flex justify-content-end'>
                <button type='submit' onClick={AddProgramme} className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body py-3'>
      
        <div className='table-responsive'>
     
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
    
            <thead>
              <tr className='fw-bold text-muted'>
               
                <th className='min-w-150px'>Name </th>
                <th className='min-w-140px'>Date Created</th>
                <th className='min-w-120px'>Last Update</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>

            <tbody>
              {value && value.map((user, index) => (
                <tr key={index}>
                 
                  <td>
                    <div className='d-flex align-items-center'>
                    
                      <div className='d-flex justify-content-start flex-column'>
                      
                        <div className='text-dark fw-bold text-hover-primary fs-6'>
                          {user?.name}
                        </div>
                       
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {new Date(user?.create_at).toLocaleDateString()}
                    </a>
                    
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>{new Date(user?.update_at).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>

                      <a
                        onClick={() => setShowEditRole(user)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </a>
                      
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
         
          </table>
        
        </div>
       
      </div>
      
    

      <Modal show={showEditRole} onHide={() => setShowEditRole('')} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Edit Programme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input className='form-control form-control-solid' type='text'
            onChange={(e) => setShowEditRole({...showEditRole,name:e?.target?.value})}
            value={showEditRole?.name} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditRole('')}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => { handleEditProgramme(showEditRole?.id, showEditRole?.name); setShowEditRole("") }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal >
    </div>
    </div>
    </div>
    </div>
  )
}

export default GlobalProgramme