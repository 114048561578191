import React, {useEffect, useState} from 'react'
import {Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font} from '@react-pdf/renderer'
import {useLocation} from 'react-router-dom'
import moment from 'moment'
import parse from 'html-react-parser'
import axios from 'axios'

Font.register({
  family: 'Open Sans',
  fonts: [
    {src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf'},
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf',
      fontWeight: 400,
    },
  ],
})
const CabSchedulePDF = () => {
  // console.log(item,"SCHEDULE DATA");
  const location = useLocation()
  const state = location.state as {item?: any; userId?: any}
  const formatDate = (dateString: string) => {
    return moment(dateString).format('MM/DD/YYYY')
  }
  const item = state?.item
  const urlId = state?.userId
  console.log(state, 'item SCHEDULE')
  const [standardData, setStandardData] = useState<any[]>([])
  const [cabData, setCabData] = useState<any>()
  const [schemeClusters, setSchemeClusters] = useState<any>([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/arc/management/schedule/data/get/?cab=${urlId}`
        )
        setCabData(response?.data?.cab_data)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }
    fetchData()
  }, [urlId])
  useEffect(() => {
    const fetchStandardData = async () => {
      try {
        const responses = await Promise.all(
          cabData?.scheme_names.map((scheme) =>
            axios.get(
              `${process.env.REACT_APP_API_URL}/global/standard/filter/?scheme=${scheme?.id}`
            )
          )
        )
        const data = responses.map((response) => response.data)
        setStandardData(data)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }

    fetchStandardData()
  }, [cabData])
  const styleObj = StyleSheet.create({
    page_1: {
      borderWidth: 8,
      borderColor: '#07408B',
      borderStyle: 'solid',
      margin: 10, // Margin only affects spacing outside the page
      padding: 20, // Adjust padding for internal spacing
    },
    view_1: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    text_1: {
      textAlign: 'center',
      fontSize: 28,
      color: '#07408B',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      marginBottom: 3,
    },
    text_2: {
      textAlign: 'center',
      fontSize: 20,
      color: '#07408B',
      fontFamily: 'Open Sans',
      fontWeight: 700,
    },
    text_3: {
      textAlign: 'center',
      fontSize: 12,
      color: '#07408B',
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    font12: {
      textAlign: 'center',
      fontSize: 12,
      color: '#333',
    },
    fontAnchor12: {
      textAlign: 'center',
      fontSize: 12,
      color: '#07408B',
    },
    text_4: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      marginTop: 8,
      marginBottom: 8,
    },
    cabname: {
      textAlign: 'center',
      fontSize: 20,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 700,
    },
    cabaddress: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    text_5: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 20,
      marginBottom: 10,
      textTransform: 'uppercase',
    },
    text_6: {
      textAlign: 'left',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      paddingLeft: 25,
      paddingRight: 25,
      marginTop: 10,
      marginBottom: 10,
    },
    textValid: {
      textAlign: 'left',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      paddingLeft: 25,
      paddingRight: 25,
      marginTop: 5,
      marginBottom: 5,
    },
    fontbold13: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    font13: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },
    margin10: {
      marginTop: 10,
      marginBottom: 10,
    },
  })
  const stylePage_2 = StyleSheet.create({
    text_2: {
      textAlign: 'center',
      fontSize: 20,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      marginTop: 15,
    },
    header_1: {
      fontSize: 17,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 10,
      marginTop: 10,
      color: '#07408B',
      textDecoration: 'underline',
    },
    table: {
      width: 'auto',
      margin: '0 auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      marginTop: 20,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableColHeader: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#f0f0f0',
      padding: 5,
      fontWeight: 700,
      textAlign: 'center',
      fontSize: 13,
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 5,
      textAlign: 'center',
      fontSize: 13,
    },
    tableCell: {
      fontSize: 8,
    },
    font13: {
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },
  })
  const stylePage_3 = StyleSheet.create({
    table: {
      width: 'auto',
      margin: '0 auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      marginTop: 20,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableColHeader: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#f0f0f0',
      padding: 5,
      fontWeight: 700,
      textAlign: 'center',
      fontSize: 13,
    },
    tableCol: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 5,
      // textAlign: 'center',
      fontSize: 13,
    },
    tableCell: {
      fontSize: 8,
    },
    font13: {
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },
  })
  const htmlToPlainText = (html) => {
    let tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    let plainText = tempDiv.textContent || tempDiv.innerText || ''
    plainText = plainText.replace(/(\.)(\s|$)/g, `$1\n`)
    return plainText
  }
  useEffect(() => {
    const getSchemeClusterName = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/account/cab/scheme/filter/?id=${urlId}`
        )
        setSchemeClusters(response.data[0]?.scheme_with_cluster)
      } catch (error) {
        console.error('API Error:', error)
      }
    }
    getSchemeClusterName()
  }, [urlId])
  return (
    <PDFViewer style={{width: '100%', height: '100vh'}}>
      <Document>
        {/* Certificate Landscape Page */}
        <Page size='A4' orientation='landscape'>
          <View style={styleObj.page_1}>
            <Image
              src='/media/logos/uaf_logo.png'
              style={{
                position: 'absolute',
                top: '50%',
                left: '35%',
                transform: 'translate(-50%, -50%)',
                width: 350,
                opacity: 0.1,
                zIndex: -1,
              }}
            />
            <View style={styleObj.view_1}>
              <Image
                src='/media/logos/uaf_logo.png'
                style={{
                  width: '150px',
                  height: '75px',
                  border: '2px solid #f3f3f3',
                  marginTop: 10,
                }}
              />
              <View style={{alignItems: 'center', width: '465px', padding: 2}}>
                <Text style={styleObj.text_1}>CERTIFICATE OF ACCREDITATION</Text>
                <Text style={styleObj.text_2}>UNITED ACCREDITATION FOUNDATION INC</Text>
                <Text style={styleObj.text_3}>
                  1060 Laskin Rd, Suite: 12B/13B, Virginia Beach VA 23451, United States of America
                </Text>
                <Text style={styleObj.text_4}>Has Accredited</Text>
                <Text style={styleObj.cabname}>{item?.cab_data?.cab_company}</Text>
                <Text style={styleObj.cabaddress}>
                  {[
                    item?.cab_data?.cab_address?.address,
                    item?.cab_data?.cab_address?.city,
                    item?.cab_data?.cab_address?.state,
                    item?.cab_data?.cab_address?.country,
                    item?.cab_data?.cab_address?.zip_code,
                  ]
                    .filter(Boolean)
                    .join(', ')}{' '}
                </Text>
              </View>
              <Image
                src='/media/iafmla.jpg'
                style={{width: '140px', height: '80px', marginTop: 5}}
              />
            </View>
            <Text style={styleObj.text_5}>
              IN THE FIELD OF {item?.programme_data?.programme_name} CERTIFICATIONS DETAILS OF SCOPE
              OF ACCREDITATION IS AS PER THE ACCREDITATION SCHEDULE ISSUED WITH THIS CERTIFICATE
            </Text>
            <View style={styleObj.view_1}>
              <Text style={styleObj.text_6}>
                ISSUE DATE:{' '}12/12/12
                {/* {item?.issue_date ? moment(new Date(item?.issue_date)).format('DD/MMM/YYYY') : '--'} */}
              </Text>
              {/* <Image
                // src="https://uafaccreditation.s3.amazonaws.com/arc/schedule/dummyimg_QPIMwC1.png"
                source={{
                  uri: 'https://uafaccreditation.s3.amazonaws.com/arc/schedule/dummyimg_QPIMwC1.png',
                }}
                style={{
                  width: '120px',
                }}
              /> */}
               {/* <Image
          src="https://uafaccreditation.s3.amazonaws.com/arc/schedule/dummyimg_QPIMwC1.png"
          style={{
            width: '120px',
          }} 
        />*/}
            </View>
            <View style={styleObj.view_1}>
              <Image
                src='/media/images/stamp.jpg'
                style={{
                  width: '120px',
                  height: '108px',
                  marginTop: 10,
                }}
              />
              <View style={{alignItems: 'center', width: '465px', padding: 2}}>
                <Text style={styleObj.textValid}> ACCREDITATION VALID FROM</Text>
                <Text style={styleObj.fontbold13}>
                  {item?.scheme_data &&
                    Object.entries(item.scheme_data)
                      .filter(([key, value]) => (value as {enabled?: boolean}).enabled)
                      .reduce((acc, [key, value]) => {
                        const {valid_date} = value as {
                          valid_date?: string
                          expiry_date?: string
                          issue_number?: string
                          enabled?: boolean
                          scheme_id?: string
                        }

                        if (valid_date) {
                          if (!acc[valid_date]) {
                            acc[valid_date] = []
                          }
                          acc[valid_date].push(key)
                        }

                        return acc
                      }, {} as Record<string, string[]>) &&
                    Object.entries(
                      Object.entries(item.scheme_data).reduce((acc, [key, value]) => {
                        const {valid_date} = value as {
                          valid_date?: string
                          enabled?: boolean
                        }

                        if (valid_date) {
                          if (!acc[valid_date]) acc[valid_date] = []
                          acc[valid_date].push(key)
                        }

                        return acc
                      }, {})
                    )
                      .map(([date, keys]: [string, unknown]) => {
                        const castedKeys = keys as string[]
                        return `${date} for ${castedKeys.join(', ')}`
                      })
                      .join('\n')}
                </Text>
                <Text style={styleObj.textValid}>ACCREDITATION EXPIRY </Text>
                <Text style={styleObj.fontbold13}>
                  {item?.scheme_data &&
                    Object.entries(item.scheme_data)
                      .filter(([key, value]) => (value as {enabled?: boolean}).enabled)
                      .reduce((acc, [key, value]) => {
                        const {expiry_date} = value as {
                          valid_date?: string
                          expiry_date?: string
                          issue_number?: string
                          enabled?: boolean
                          scheme_id?: string
                        }

                        if (expiry_date) {
                          if (!acc[expiry_date]) {
                            acc[expiry_date] = []
                          }
                          acc[expiry_date].push(key)
                        }

                        return acc
                      }, {} as Record<string, string[]>) &&
                    Object.entries(
                      Object.entries(item.scheme_data).reduce((acc, [key, value]) => {
                        const {expiry_date} = value as {
                          expiry_date?: string
                          enabled?: boolean
                        }

                        if (expiry_date) {
                          if (!acc[expiry_date]) acc[expiry_date] = []
                          acc[expiry_date].push(key)
                        }

                        return acc
                      }, {})
                    )
                      .map(([date, keys]: [string, unknown]) => {
                        const castedKeys = keys as string[]
                        return `${date} for ${castedKeys.join(', ')}`
                      })
                      .join('\n')}
                </Text>
              </View>
              <Text style={styleObj.text_6}> (DIRECTOR)</Text>
            </View>

            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: '#333',
                fontFamily: 'Open Sans',
                fontWeight: 600,
                marginBottom: 10,
                marginTop: 25,
              }}
            >
              UNITED ACCREDITATION FOUNDATION INC (UAF)
              <Text style={styleObj.font13}>
                {' '}
                is a signatory of the Multilateral Recognition arrangement with International
                Accreditation Forum (IAF) and a signatory of the Mutual Recognition Arrangement with
                Asia Pacific Accreditation Cooperation (APAC).
              </Text>
            </Text>
            <Text style={styleObj.font12}>
              Visit <Text style={styleObj.fontAnchor12}>www.uafaccreditation.org</Text> for current
              accreditation information.
            </Text>
          </View>
        </Page>

        {/* A4 Portrait Page */}
        <Page size='A4' style={{padding: 25}}>
          <Text style={stylePage_2.text_2}>Schedule of Accreditation</Text>
          <Text style={stylePage_2.header_1}>
            {' '}
            Issue No:{item?.issue_number}, Issue Date:{' '}
            {moment(new Date(item?.accreditation_valid_date)).format('DD/MMM/YYYY')}
          </Text>
          <Text
            style={{
              fontSize: 13,
              color: '#333',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              marginBottom: 5,
            }}
          >
            {' '}
            Name of CAB:<Text style={stylePage_2.font13}>{item?.cab_data?.cab_company} </Text>
          </Text>
          <Text
            style={{
              fontSize: 13,
              color: '#333',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              marginBottom: 5,
            }}
          >
            {' '}
            Key Location (s):
            <Text style={stylePage_2.font13}>
              {' '}
              {[
                item?.key_location?.address,
                item?.key_location?.city,
                item?.key_location?.state,
                item?.key_location?.country,
                item?.key_location?.zip_code,
              ]
                .filter(Boolean)
                .join(', ')}{' '}
            </Text>
          </Text>

          <Text
            style={{
              fontSize: 13,
              color: '#333',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              marginBottom: 5,
            }}
          >
            Countries where UAF accredited certificates can be Issued (Subject to Compliance with
            UAF Policy For CAB Operations in Sanctioned Countries):
            <Text style={stylePage_2.font13}>
              {' '}
              Countries: {item?.country_data?.map((country) => country?.name).join(', ')} (subject
              to approval of local authorities){' '}
            </Text>
          </Text>
          <View style={stylePage_2.table}>
            {/* Table Header */}
            <View style={stylePage_2.tableRow}>
              <Text style={stylePage_2.tableColHeader}>Accredited to ISO/IEC 17021-1</Text>
              <Text style={stylePage_2.tableColHeader}>Accreditation Number</Text>
              <Text style={stylePage_2.tableColHeader}>Accreditation Valid Date</Text>
              <Text style={stylePage_2.tableColHeader}>Accreditation Valid Up to*</Text>
            </View>
            {item?.scheme_data &&
              Object.entries(item.scheme_data)
                .filter(([key, value]) => (value as {enabled: boolean}).enabled)
                .map(([key, value]) => {
                  const scheme = value as {
                    valid_date: string
                    expiry_date: string
                    issue_number: string
                    enabled: boolean
                    scheme_id: string
                  }
                  return (
                    <View style={stylePage_2.tableRow} key={key}>
                      <Text style={stylePage_2.tableCol}>{scheme.issue_number}</Text>
                      <Text style={stylePage_2.tableCol}>{scheme.issue_number}</Text>
                      <Text style={stylePage_2.tableCol}>{scheme.valid_date}</Text>
                      <Text style={stylePage_2.tableCol}>{scheme.expiry_date}</Text>
                    </View>
                  )
                })}
          </View>
          {item?.accreditation_standards ? (
            <View>
              <Text style={stylePage_2.text_2}>ACCREDITATION STANDARDS</Text>

              <Text style={stylePage_2.font13}>
                {htmlToPlainText(item?.accreditation_standards)}
              </Text>
            </View>
          ) : null}
          {item?.scheme_data ? (
            <View>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  color: '#333',
                  fontFamily: 'Open Sans',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  marginTop: 15,
                }}
              >
                {' '}
                {item?.programme_data?.programme_name} CERTIFICATION STANDARDS
              </Text>

              <View>
                {item?.scheme_data &&
                  Object.entries(item.scheme_data)
                    .filter(([key, value]) => (value as {enabled: boolean}).enabled)
                    .map(([key, value]) => {
                      const scheme = value as {
                        valid_date: string
                        expiry_date: string
                        issue_number: string
                        enabled: boolean
                        scheme_id: string
                      }

                      const matchedStandard = standardData?.find(
                        (standard) => standard[0]?.scheme === scheme.scheme_id
                      )

                      if (matchedStandard) {
                        return (
                          <View
                            key={scheme.scheme_id}
                            style={{flexDirection: 'row', alignItems: 'center', marginBottom: 5}}
                          >
                            <Text style={stylePage_2.font13}>
                              {matchedStandard[0]?.name} - {matchedStandard[0]?.scheme_name} --
                              Requirements
                            </Text>
                          </View>
                        )
                      }
                      return null
                    })}
              </View>
            </View>
          ) : null}
          <View>
            <Text style={stylePage_2.text_2}>SCOPE OF ACCREDITATION </Text>

            <View>
              {/* Table Header */}

              {item?.scheme_data &&
                Object.entries(item.scheme_data)
                  .filter(([key, value]) => (value as {enabled: boolean}).enabled) // Only process enabled items in scheme_data
                  .map(([key, value]) => {
                    const scheme = value as {
                      valid_date: string
                      expiry_date: string
                      issue_number: string
                      enabled: boolean
                      scheme_id: string
                    }

                    // Check if this scheme_id exists and is enabled in schemeClusters
                    const matchedCluster = schemeClusters?.find(
                      (cluster) => cluster.scheme_id === scheme.scheme_id
                    )

                    // Render only if there's a matching enabled cluster
                    if (matchedCluster) {
                      return (
                        <View style={{marginBottom: 10}}>
                          <Text
                            style={{
                              marginBottom: 5,
                              fontSize: 13,
                              color: '#333',
                              fontFamily: 'Open Sans',
                              fontWeight: 600,
                            }}
                          >
                            {matchedCluster.scheme_name}
                          </Text>
                          <View style={stylePage_3.tableRow}>
                            <Text style={stylePage_3.tableColHeader}> IAF Scope</Text>
                            <Text style={stylePage_3.tableColHeader}> Description</Text>
                          </View>
                          {matchedCluster.clusters?.map((clusterItem) => {
                            const [iafScope, description] = clusterItem?.name.split(' - ', 2)
                            return (
                              <View style={stylePage_3.tableRow} key={clusterItem?.id}>
                                <Text style={stylePage_3.tableCol}>{iafScope}</Text>
                                <Text style={stylePage_3.tableCol}> {description}</Text>
                              </View>
                            )
                          })}
                        </View>
                      )
                    }

                    // Return null if no matching cluster
                    return null
                  })}
            </View>
          </View>
          {item?.remark ? (
            <View>
              <Text style={stylePage_2.text_2}>Remarks</Text>
              <Text style={stylePage_2.font13}>{htmlToPlainText(item?.remark)}</Text>
            </View>
          ) : null}
          <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
            <Image
              src={item?.member_signature}
              style={{
                width: 120, 
                height: 'auto', 
               }}
            />
            <Text
              style={{
                fontSize: 13,
                color: '#333',
                fontFamily: 'Open Sans',
                paddingRight:40
              }}
            >
              DIRECTOR
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default CabSchedulePDF
