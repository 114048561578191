import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Dropdown, DropdownButton, Modal, Button} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {toast} from 'react-toastify'
import AllAssessor from './AllAssessor'
import AllCab from './AllCab'
import AllOther from './AllOther'

const AllInvoice = () => {
  const [activeTab, setActiveTab] = useState('Assessor')
  return (
    <div className='card card-flush' style={{borderRadius:"0px"}}>
      <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 p-4'>
        <li className='nav-item'>
          <a
            className={`nav-link ${activeTab === 'Assessor' ? 'active' : ''}`}
            href='#'
            onClick={() => setActiveTab('Assessor')}
          >
            Assessor
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={`nav-link ${activeTab === 'CAB' ? 'active' : ''}`}
            href='#'
            onClick={() => setActiveTab('CAB')}
          >
            CAB
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={`nav-link ${activeTab === 'Other' ? 'active' : ''}`}
            href='#'
            onClick={() => setActiveTab('Other')}
          >
            Other
          </a>
        </li>
      </ul>

      <div>
        {activeTab === 'Assessor' && <AllAssessor />}
        {activeTab === 'CAB' && <AllCab />}
        {activeTab === 'Other' && <AllOther/>}
      </div>
      
    </div>
  )
}

export default AllInvoice
