import { lazy, FC, Suspense } from 'react'
import { useAuth } from '../modules/auth'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import BusinessUnit from '../modules/organisation/businessUnit/BusnessUnit'
import UnitFunction from '../modules/organisation/unitFunction/UnitFunction'
import { BusinessUnitSetting } from '../modules/organisation/businessUnit/BusinessUnitSetting'
import AssessorsLeads from '../modules/assessor-mangement/assessorLead/AssessorsLeads'
// import { AssessorDetails } from '../modules/assessor-mangement/assessorLead/components/AssessorDetails'
// import { AssessorOverview } from '../modules/assessor-mangement/assessorLead/components/AssessorOverview'
import AssessorPage from '../modules/assessor-mangement/assessorLead/AssessorPage'
import VerifiedAssessorPage from '../modules/assessor-mangement/verifiedAssessor/VerifiedAssessorPage'
import VerifiedAssessorsLeads from '../modules/assessor-mangement/verifiedAssessor/VerifiedAssessorsLeads'
import ResourceMatrix from '../modules/resource-matrix/ResourceMatix'
import ResourceMatrixQMS from '../modules/resource-matrix/ResourceMatrixQMS'
import ResourceMatrixEMS from '../modules/resource-matrix/ResourceMatrixEMS'
import ResourceMatrixOHSMS from '../modules/resource-matrix/ResourceMatrixOHSMS'
import ResourceMatrixFSMS from '../modules/resource-matrix/ResourceMatrixFSMS'
import ResourceMatrixMDQMS from '../modules/resource-matrix/ResourceMatrixMDQMS'
import ResourceMatrixTL from '../modules/resource-matrix/ResourceMatrixTL'
import ResourceMatrixIB from '../modules/resource-matrix/ResourceMatrixIB'
// import ResourceMatrix from '../modules/assessor-mangement/assessorLead/components/ResourceMatix'
import AssessmentPlanner from '../modules/organisation/AssessmentPlanner/AssessmentPlanner'
import HistoricalAssessment from '../modules/organisation/HistoricalAssessment/HistoricalAssessment'
import CreateAssessorManaul from '../modules/assessor-mangement/CreateManualAssesor'
import TrainingData from '../modules/assessor-mangement/TrainingData'
import ClusterTrainingData from '../modules/assessor-mangement/AddClusterData'
import AddAssessorPage from '../modules/assessor-mangement/AddAssessorPage'
import AddAssessorManual from '../modules/assessor-mangement/verifiedAssessor/AddManualAssosser'
import AddManualAssessor from '../modules/assessor-mangement/verifiedAssessor/AddManualAssosser'
import NCManagementForm from '../modules/nc-mangement/NCManagementForm'
import RoleMatrix from '../modules/team-mangement/RoleMatrix'
import CabNCForm from '../modules/nc-mangement/CabNCForm'
import { NCManagementList } from '../modules/nc-mangement/NCManagementList'
import AssessorNC from '../modules/nc-mangement/AssessorNC'
import CreateAssessmentPlanner from '../modules/organisation/CreateAssessmentPlanner/CreateAssessmentPlanner'
import CreateARCReport from '../modules/arc-management/CreateARCReport'
import Certificates from '../modules/cetificates/Certificates'
import NCDashboard from '../modules/nc-mangement/NCDashboard'
import NCEdit from '../modules/nc-mangement/NCEdit'
import CreateAnnexureReport from '../modules/arc-management/CreateAnnexureReport'
import AssessmentPlannerDetail from '../modules/organisation/AssessmentPlanner/component/AssessmentPlannerDetail'
import CABAssessmentProgram from '../modules/apps/cab-management/cab-list/components/cab-assessment/CABAssessmentProgram'
import AccreditationSchedule from '../modules/apps/cab-management/cab-list/components/cab-assessment/AccreditationSchedule'
import ARClist from '../modules/arc-management/ARClist'
import CABarcDetails from '../modules/arc-management/CABarcDetails'
import GenerateDARCreport from '../modules/arc-management/generateArc/GeneratedARCreport'
import ARCAssign from '../modules/arc-management/ARCAssign'
import UAFDocuments from '../modules/uafDocuments/UAFDocuments'
import AllUsers from '../modules/team-mangement/components/AllUsers'
import GeneratedCABreport from '../modules/apps/cab-management/cab-list/components/cab-assessment/cab-assessment-report/GeneratedCABreport'
import EditCabAssessment from '../modules/apps/cab-management/cab-list/components/cab-assessment/Edit-Cab-Assessment/EditCabAssessment'
import CABNCReport from '../modules/profile/components/CABNCReport'
import ArcMemberLists from '../modules/assessor-mangement/verifiedAssessor/components/ArcMemberLists'
import ApplicationReviewerMonitoring from '../modules/performance-monitoring/ApplicationReviewerMonitoring'
import AssessorMonitoring from '../modules/performance-monitoring/AssessorMonitoring'
import ArcMonitoring from '../modules/performance-monitoring/ArcMonitoring'
import EditApplicationReviewer from '../modules/performance-monitoring/EditApplicationReviewer'
import EditAssessorMonitoring from '../modules/performance-monitoring/EditAssessorMonitoring'
import EditArcMonitoring from '../modules/performance-monitoring/EditArcMonitoring'
import AddCertificateData from '../modules/profile/components/AddCertificateData'
import { AllCabCertificate } from '../modules/apps/cab-user/Approved-cab-user/components/AllCabCertificate'
import AddEditAllCertificate from '../modules/apps/cab-user/Approved-cab-user/components/AddEditAllCertificate'
import CabAuditor from '../modules/profile/components/CabAuditor'
import AddBlog from '../pages/content-management/AddBlog'
import BlogList from '../pages/content-management/BlogList'
import TrainingList from '../pages/training-management/TrainingList'
import AddTraining from '../pages/training-management/AddTraining'
import Suspended from '../pages/content-management/Suspended'
import AddSuspended from '../pages/content-management/AddSuspended'
import Notices from '../pages/content-management/Notices'
import AddNotice from '../pages/content-management/AddNotice'
import InvoiceHeader from '../modules/invoice-module/InvoiceHeader'
import AllInvoice from '../modules/invoice-module/AllInvoice'
import InvoicePage from '../modules/invoice-module/InvoicePage'
import CreateInvoice from '../modules/invoice-module/CreateInvoice'
import { usePermissions } from '../permission_component/PermissionContext'
import AddReport from '../pages/dashboard/Report/AddReport'
import CabSchedulePDF from '../modules/profile/components/CabSchedulePDF'
import GlobalProgramme from '../modules/global/GlobalProgramme'
import GlobalScheme from '../modules/global/GlobalScheme'
import GlobalStandard from '../modules/global/GlobalStandard'
import GlobalCluster from '../modules/global/GlobalCluster'
// import { BusinessUnitSetting } from '../modules/organisation/businessUnit/businessUnitSetting'
// import BusinessUnitPage from '../modules/organisation/businessUnit/businessUnitPage'
// import { Overview } from '../modules/organisation/businessUnit/businessUnitOverview'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const TeamPage = lazy(() => import('../modules/team-mangement/TeamPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CabPage = lazy(() => import('../modules/apps/cab-management/CabPage'))
  const ApprovedCabPage = lazy(() => import('../modules/apps/cab-user/ApprovedCabPage'))
  const CreateAssessment = lazy(() => import('../modules/apps/create-assessment/CreateAssessment'))
  const BusinessUnitPage = lazy(
    () => import('../modules/organisation/businessUnit/BusinessUnitPage')
  )

  const { currentUser } = useAuth()
  const userId = localStorage.getItem('current_user_id')
  console.log(currentUser, 'currentUser')
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {currentUser?.is_verify_accessor === true ? (<>
          <Route path="/*" element={<Navigate to={`/assessor/verified/${currentUser?.id}/overview`} />} />
          <Route path='/dashboard' element={<DashboardWrapper />} />
          </>
        ) : currentUser?.is_cab_verify === true ? (
          <>
          <Route path="/*" element={<Navigate to={`/crafted/pages/profile/${currentUser?.id}/overview`} />} />
          <Route path='/dashboard' element={<DashboardWrapper />} />
          </>
        ) : (
          <>
            <Route path='/dashboard' element={<DashboardWrapper />} />
            <Route path="/*" element={<Navigate to='/dashboard' />} />
          </>
        )

        }


        {/* Pages */}

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {/* <Route
          path='/addcertificates*'
          element={
            <SuspensedView>
              <CabApplication />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/organisation/businessunit/*'
          element={
            <SuspensedView>
              <BusinessUnit />
            </SuspensedView>
          }
        />
        <Route
          path='/NCManagement/form/*'
          element={
            <SuspensedView>
              <NCManagementForm />
            </SuspensedView>
          }
        />
        <Route
          path='/NCManagement/edit/:id/*'
          element={
            <SuspensedView>
              <NCEdit />
            </SuspensedView>
          }
        />

        {/* <Route
          path='/NCManagement/cab/:id*'
          element={
            <SuspensedView>
              <CabNCForm/>
            </SuspensedView>
          }
        /> */}
        <Route
          path='/NCManagement/Dashboard/*'
          element={
            <SuspensedView>
              <NCDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/NCManagement/list/*'
          element={
            <SuspensedView>
              <NCManagementList />
            </SuspensedView>
          }
        />
        <Route
          path='/assessor/leads/*'
          element={
            <SuspensedView>
              <AssessorsLeads />
            </SuspensedView>
          }
        />
        {/* <Route
          path="/assessor/details/edit/*"
          element={
            <SuspensedView>
              <AddAssessorPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/add/assessor/*'
          element={
            <SuspensedView>
              <AddAssessorManual />
            </SuspensedView>
          }
        />
        <Route
          path='/assessor/verified/list/*'
          element={
            <SuspensedView>
              <VerifiedAssessorsLeads />
            </SuspensedView>
          }
        />
        <Route
          path='/assessor/arc_member/*'
          element={
            <SuspensedView>
              <ArcMemberLists />
            </SuspensedView>
          }
        />
        <Route
          path='/cab/certificate/:id'
          element={
            <SuspensedView>
              <AddCertificateData />
            </SuspensedView>
          }
        />
        <Route
          path='/cab/auditor/:id'
          element={
            <SuspensedView>
              <CabAuditor />
            </SuspensedView>
          }
        />
        <Route
          path='/resource-matrix-global'
          element={
            <SuspensedView>
              <ResourceMatrix />
            </SuspensedView>
          }
        />
        <Route
          path='/resource-matrix-qms'
          element={
            <SuspensedView>
              <ResourceMatrixQMS />
            </SuspensedView>
          }
        />
        <Route
          path='/resource-matrix-ems'
          element={
            <SuspensedView>
              <ResourceMatrixEMS />
            </SuspensedView>
          }
        />
        <Route
          path='/resource-matrix-ohsms'
          element={
            <SuspensedView>
              <ResourceMatrixOHSMS />
            </SuspensedView>
          }
        />
        <Route
          path='/resource-matrix-fsms'
          element={
            <SuspensedView>
              <ResourceMatrixFSMS />
            </SuspensedView>
          }
        />
        <Route
          path='/resource-matrix-mdqms'
          element={
            <SuspensedView>
              <ResourceMatrixMDQMS />
            </SuspensedView>
          }
        />
        <Route
          path='/resource-matrix-tl'
          element={
            <SuspensedView>
              <ResourceMatrixTL />
            </SuspensedView>
          }
        />
        <Route
          path='/resource-matrix-ib'
          element={
            <SuspensedView>
              <ResourceMatrixIB />
            </SuspensedView>
          }
        />
        <Route
          path='/globalprogramme'
          element={
            <SuspensedView>
              <GlobalProgramme/>
            </SuspensedView>
          }
        />
        <Route
          path='/globalscheme'
          element={
            <SuspensedView>
              <GlobalScheme/>
            </SuspensedView>
          }
        />
        <Route
          path='/globalstandard'
          element={
            <SuspensedView>
              <GlobalStandard/>
            </SuspensedView>
          }
        />
        <Route
          path='/globalcluster'
          element={
            <SuspensedView>
              <GlobalCluster/>
            </SuspensedView>
          }
        />
        {/* <Route
          path='/assessor/overview/*'
          element={
            <SuspensedView>
              <AssessorDetails />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/assessor/*'
          element={
            <SuspensedView>
              <AssessorPage />
            </SuspensedView>
          }
        />
        <Route
          path='/assessor/verified/*'
          element={
            <SuspensedView>
              <VerifiedAssessorPage />
            </SuspensedView>
          }
        />
        <Route
          path='/assessor/details/*'
          element={
            <SuspensedView>
              <AddAssessorPage />
            </SuspensedView>
          }
        />
        <Route
          path='/organisation/unitfunction/*'
          element={
            <SuspensedView>
              <UnitFunction />
            </SuspensedView>
          }
        />
        <Route
          path='/organisation/CreateAssessment/*'
          element={
            <SuspensedView>
              <CreateAssessmentPlanner />
            </SuspensedView>
          }
        />
        <Route
          path='/organisation/AssessmentPlanner/*'
          element={
            <SuspensedView>
              <AssessmentPlanner />
            </SuspensedView>
          }
        />
        <Route
          path='organisation/AssessmentPlanner/detail/*'
          element={
            <SuspensedView>
              <AssessmentPlannerDetail />
            </SuspensedView>
          }
        />
        <Route
          path='/organisation/HistoricalAssessment/*'
          element={
            <SuspensedView>
              <HistoricalAssessment />
            </SuspensedView>
          }
        />
        <Route
          path='/organisation/businessunit/details/*'
          element={
            <SuspensedView>
              <BusinessUnitPage />
            </SuspensedView>
          }
        />

        <Route
          path='/organisation/businessunit/setting/*'
          element={
            <SuspensedView>
              <BusinessUnitSetting />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/userteam/*'
          element={
            <SuspensedView>
              <TeamPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/certificates/*'
          element={
            <SuspensedView>
              <Certificates />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/userteam/rolematrix'
          element={
            <SuspensedView>
              <RoleMatrix />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/addblog'
          element={
            <SuspensedView>
              <AddBlog />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/blog/:id'
          element={
            <SuspensedView>
              <AddBlog />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/bloglist'
          element={
            <SuspensedView>
              <BlogList />
            </SuspensedView>
          }
        />
        <Route
          path='/invoice/*'
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='/create-invoice'
          element={
            <SuspensedView>
              <CreateInvoice />
            </SuspensedView>
          }
        />
        {/* training route */}

        <Route
          path='/crafted/addtraining'
          element={
            <SuspensedView>
              <AddTraining />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/addSuspended'
          element={
            <SuspensedView>
              <AddSuspended />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/Suspended/:id'
          element={
            <SuspensedView>
              <AddSuspended />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/suspended'
          element={
            <SuspensedView>
              <Suspended />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/training/:id'
          element={
            <SuspensedView>
              <AddTraining />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/traininglist'
          element={
            <SuspensedView>
              <TrainingList />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/addNotices'
          element={
            <SuspensedView>
              <AddNotice />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/Notices/:id'
          element={
            <SuspensedView>
              <AddNotice />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/notices'
          element={
            <SuspensedView>
              <Notices />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/uafdocuments/*'
          element={
            <SuspensedView>
              <UAFDocuments />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/userteam/allusers'
          element={
            <SuspensedView>
              <AllUsers />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/cab-management/*'
          element={
            <SuspensedView>
              <CabPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/apps/cab-assessment/program/'
          element={
            <SuspensedView>
              <CABAssessmentProgram />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/apps/cab/assessment/:id'
          element={
            <SuspensedView>
              <CABAssessmentProgram />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/cab/assessment/edit/:id'
          element={
            <SuspensedView>
              <EditCabAssessment />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/cab/nc/report/:id'
          element={
            <SuspensedView>
              <CABNCReport />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/apps/cab/report/'
          element={
            <SuspensedView>
              <GeneratedCABreport/>
            </SuspensedView>
          }
        /> */}
        <Route
          path='/apps/cab/accreditation-schedule/:id'
          element={
            <SuspensedView>
              <AccreditationSchedule />
            </SuspensedView>
          } 
        />
       <Route
          path='/apps/cab/accreditation/schedule/:id/:sId'
          element={
            <SuspensedView> 
              <AccreditationSchedule />
            </SuspensedView>
          }
        />
        <Route  
          path='apps/cab-user/*'
          element={
            <SuspensedView>
              <ApprovedCabPage />
            </SuspensedView>
          }
        />
        <Route
          path='cab/certificates/add'
          element={
            <SuspensedView>
              <AddEditAllCertificate />
            </SuspensedView>
          }
        />
        <Route
          path='cab/reports/add'
          element={
            <SuspensedView>
              <AddReport />
            </SuspensedView>
          }
        />
        
        <Route
          path='apps/all/cab/certificates/*'
          element={
            <SuspensedView>
              <AllCabCertificate />
            </SuspensedView>
          }
        />
        <Route
          path='apps/create-assessment/*'
          element={
            <SuspensedView>
              <CreateAssessment />
            </SuspensedView>
          }
        />
        <Route
          path='create/assessor/*'
          element={
            <SuspensedView>
              <AddManualAssessor />
            </SuspensedView>
          }
        />
        <Route
          path='/accreditation/cab/report/create/cab_id/:cab_id/arc_member/:arc_member/arc_no/:arc_no'
          // path='/accreditation/cab/report/create/:id'
          element={
            <SuspensedView>
              <CreateARCReport />
            </SuspensedView>
          }
        />
        <Route
          path='/accreditation/cab/arc/list/*'
          element={
            <SuspensedView>
              <ARClist />
            </SuspensedView>
          }
        />
        <Route
          path='/accreditation/cab/assign/arc/*'
          element={
            <SuspensedView>
              <ARCAssign />
            </SuspensedView>
          }
        />
        <Route
          path='/accreditation/cab/arc/*'
          element={
            <SuspensedView>
              <CABarcDetails />
            </SuspensedView>
          }
        />

        <Route
          path='/apps/performance-monitoring/appication-reviewer'
          element={
            <SuspensedView>
              <ApplicationReviewerMonitoring />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/performance-monitoring/application-reviewer/edit/:id'
          element={
            <SuspensedView>
              <EditApplicationReviewer />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/performance-monitoring/assessor-monitoring'
          element={
            <SuspensedView>
              <AssessorMonitoring />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/performance-monitoring/assessor-monitoring/edit/:id'
          element={
            <SuspensedView>
              <EditAssessorMonitoring />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/performance-monitoring/arc-member-monitoring'
          element={
            <SuspensedView>
              <ArcMonitoring />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/performance-monitoring/arc-member-monitoring/edit/:id'
          element={
            <SuspensedView>
              <EditArcMonitoring />
            </SuspensedView>
          }
        />


        <Route
          path='/are/generated/report/cab/:cab_id/arc/:arc_member_id/arc_no/:arc_no'
          element={
            <SuspensedView>
              <GenerateDARCreport />
            </SuspensedView>
          }
        />
          <Route
          path='/pdf'
          element={
            <SuspensedView>
              <CabSchedulePDF />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/accreditation/cab/report/'
          element={
            <SuspensedView>
              <CreateARCReport />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
export { PrivateRoutes }
