import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useLocation, useParams} from 'react-router-dom'
import moment from 'moment'
import {Document, Packer, Paragraph, TextRun, HeadingLevel} from 'docx'
import {saveAs} from 'file-saver'
import GenerateDr from './GenerateDr'
import GenerateOa from './GenerateOa'
import GenerateWr from './GenerateWr'
import GenerateQMSax from './generateAnnexureReport/GenerateQMSax'
import GenerateEMSax from './generateAnnexureReport/GenerateEMSax'
import GenerateOHSMSax from './generateAnnexureReport/GenerateOHSMSax'
import GenerateFSMSax from './generateAnnexureReport/GenerateFSMSax'
import GenerateMDQMSax from './generateAnnexureReport/GenerateMDQMSax'
import GenerateFinalReport from './GenerateFinalReport'
import GenerateApprovalReport from './GenerateApprovalReport'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
interface DocumentReview {
  id: string
  review_report_qa: string
  review_report_qb: string
  cab: string
  review: string[]
}
type Assessor = {
  id: string
  first_name: string
  last_name: string
}

type AssessorData = {
  update_at?: string
  scheme_name?: string
  assessment_number?: string
  assessor_data?: Assessor[]
  assessor_planner_updated_at?: string
}

type DocumentReviewData = {
  assessor_data?: AssessorData[]
  review_report_qa?: string
  review_report_qb?: string
}
interface LocationState {
  targetvalue?: any
}
const GenerateDARCreport = () => {
  const location = useLocation()
  const state = location.state as LocationState
  console.log(state, 'this is state')
  const {targetvalue} = state || {}

  console.log(targetvalue, 'this is the target value')
  const documentReviewItem = targetvalue.find((item) => item.type === 'Document Review')
  const officeItem = targetvalue.find((item) => item.type === 'Office Assessment')
  const witnessItem = targetvalue.find((item) => item.type === 'Witness Assessment')

  // Get the assessment_number of the found item
  const documentNumber = documentReviewItem?.assessment_number
  const officeNumber = officeItem?.assessment_number
  const witnessNumber = witnessItem?.assessment_number

  const {cab_id, arc_member_id, arc_no} = useParams()
  const userId = cab_id
  const ARC_member = arc_member_id
  const ARC_no = arc_no
  const API_URL = process.env.REACT_APP_API_URL
  const [cabData, setCabData] = useState<any>()
  const [CABSchemeData, setCABSchemeData] = useState<any>()
  console.log(CABSchemeData, 'CABSchemeData')

  useEffect(() => {
    if (userId) {
      const getUserDetails = async () => {
        try {
          const response = await axios.get(`${API_URL}/account/patch/user/${userId}/`)
          setCabData(response.data)
          setCABSchemeData(response.data?.user_schemes)
        } catch (error) {
          console.error('API Error:', error)
          // throw error;
        }
      }
      getUserDetails()
    }
  }, [userId])
  const QmsSchemeID = '745048c9-17e9-40bc-a8d7-6ef2a91e9aee'
  const OHSMSID = 'df28ddf3-b330-47d8-abec-a44333e4aabb'
  const FSMSID = '40f65889-bbe5-4784-9e10-38863a220be9'
  const EMSID = '07f858f5-7e61-4780-baf0-b1ff97210de1'
  const MDQMSID = '3d350ed1-ae27-44fe-8b2a-3c6a6029b774'

  const hasQmsScheme = CABSchemeData?.includes(QmsSchemeID)
  const hasOhsmsScheme = CABSchemeData?.includes(OHSMSID)
  const hasFsmsScheme = CABSchemeData?.includes(FSMSID)
  const hasEmsScheme = CABSchemeData?.includes(EMSID)
  const hasMdqmsScheme = CABSchemeData?.includes(MDQMSID)
  const noSchemeSelected = !(
    hasQmsScheme ||
    hasOhsmsScheme ||
    hasFsmsScheme ||
    hasEmsScheme ||
    hasMdqmsScheme
  )

  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()
        const imgProps = pdf.getImageProperties(imgData)
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width

        let heightLeft = imgHeight
        let position = 0

        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
        heightLeft -= pdfHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
          heightLeft -= pdfHeight
        }

        pdf.save('FInalARCReport.pdf')
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
    }
  }

  return (
    <div className='container'>
      <div id='pdfContent'>
        <div className='container'>
          <div className='row'>
            <div className='d-flex flex-column justify-content-center align-items-center w-100'>
              <img
                alt='Logo'
                src={'/media/logos/uaf_logo.png'}
                className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
              />
              <div className='uaf_title py-5 text-center'>
                <h2 className=''>ACCREDITATION REVIEW COMMITTEE REPORT</h2>
                <span className='fs-18'>ACCREDITATION PROCESS </span>
              </div>
            </div>
          </div>

          {/* Cab info */}
          <div
            className='mb-5 mb-xl-10'
            id='kt_profile_details_view'
            style={{width: '100%', background: '#fff', padding: '25px', borderRadius: '5px'}}
          >
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>
                  CAB INFORMATION<span style={{color: 'red'}}>*</span>
                </h3>
              </div>
            </div>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <div className='col-lg-3'>CAB Name:</div>
                <div className='col-lg-9'>{cabData?.company}</div>
              </div>
              <div className='row mb-7'>
                <div className='col-lg-3'>Critical Location:</div>
                <div className='col-lg-9'>{cabData?.address}</div>
              </div>
              <div className='row mb-7'>
                <div className='col-lg-3'>Other Locations:</div>
                <div className='col-lg-9'>{cabData?.company}</div>
              </div>
              <div className='row mb-7'>
                <div className='col-lg-3'>Scheme(s) of accreditation for ARC:</div>
                <div className='col-lg-9'>
                  <ul>
                    {cabData?.scheme_names?.map((item) => (
                      <li key={item?.id}>{item?.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*Document review*/}
          <GenerateDr
            userId={userId}
            ARC_member={ARC_member}
            ARC_no={ARC_no}
            documentNumber={documentNumber}
          />

          {/*office review*/}
          <GenerateOa
            userId={userId}
            ARC_member={ARC_member}
            ARC_no={ARC_no}
            officeNumber={officeNumber}
          />

          {/* witness review */}
          <GenerateWr
            userId={userId}
            ARC_member={ARC_member}
            ARC_no={ARC_no}
            witnessNumber={witnessNumber}
          />
          {!noSchemeSelected && (<h2 className='text-center'>Scopes of Accreditation</h2>)}

          {hasQmsScheme && (
            <GenerateQMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
          )}
          {/* QMS Annexure */}
          {hasEmsScheme && (
            <GenerateEMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
          )}
          {hasOhsmsScheme && (
            <GenerateOHSMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
          )}
          {hasFsmsScheme && (
            <GenerateFSMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
          )}
          {hasMdqmsScheme && (
            <GenerateMDQMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
          )}
          {/* final report */}
          <GenerateFinalReport userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />

          <GenerateApprovalReport userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
        </div>
      </div>
      <div className='text-end m-5'>
        <button className='btn btn-primary' onClick={generatePDF}>
          Download
        </button>
      </div>
    </div>
  )
}

export default GenerateDARCreport
