import axios from 'axios'
import {AnyMxRecord} from 'dns'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {toast} from 'react-toastify'
import NumberTOWord from './NumberTOWord'

const CabInitialFee = ({selectedOption}) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  interface User {
    id: number
    company: string
  }
  const [cabUser, setCabUser] = useState<any>()
  const [cabId, setCabID] = useState<any>()
  const [invoiceNumber, setInvoiceNumber] = useState<any>()
  const [invoiceDate, setInvoiceDate] = useState(() => new Date().toISOString().split('T')[0])
  const [value, setValue] = useState<User[]>([])

  console.log(cabUser, 'cabUser')
  useEffect(() => {
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)
      .then((response) => {
        const data = response.data?.map((item) => ({
          label: `${item?.company}`,
          value: item?.id,
        }))
        setValue(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const [table_data, setRows] = useState([
    {id: 1, details: ' ', fee: 0, mandayRate: 0, totalAmount: 0},
  ])
  const [subTotal, setSubTotal] = useState<any>(0)
  const [grandTotal, setGrandTotal] = useState<any>(0)
  const [netPayable, setNetPayable] = useState<any>(0)
  //   const [summary, setSummary] = useState({
  //     subtotal: 0,
  //     grandTotal: 0,
  //     netPayable: 0,
  //   })
  console.log(table_data, 'SHOW ROW')
  const [tax, setTax] = useState<any>(0)
  const addRow = () => {
    const newId = table_data.length + 1
    const newRow = {id: newId, details: '', fee: 0, mandayRate: 0, totalAmount: 0}
    setRows([...table_data, newRow])
  }

  const calculateSubtotal = () => {
    return table_data.reduce((sum, row) => sum + row.totalAmount, 0)
  }
  const calculateGrandTotal = () => {
    return calculateSubtotal() + (parseFloat(tax) || 0) // Ensure tax is a valid number
  }

  const handleInputChange = (index, field, value) => {
    const updatedRows = table_data.map((row, i) => {
      if (i === index) {
        // Update the row based on the field being edited
        const updatedRow = {
          ...row,
          [field]:
            field === 'fee' || field === 'mandayRate'
              ? parseFloat(value.replace(/^0+/, '')) // Remove leading zeros for numbers
              : value, // For details, use the value as-is
        }

        // Automatically calculate the total amount if fee or mandayRate is updated
        if (field === 'fee' || field === 'mandayRate') {
          updatedRow.totalAmount = updatedRow.fee * updatedRow.mandayRate
        }

        return updatedRow
      }
      return row
    })

    setRows(updatedRows)
  }
  useEffect(() => {
    const newSubtotal = calculateSubtotal()
    const newGrandTotal = newSubtotal + (parseFloat(tax) || 0)
    setSubTotal(newSubtotal)
    setGrandTotal(newGrandTotal)
    setNetPayable(newGrandTotal)
  }, [table_data, tax]) // Trigger recalculation whenever rows or tax change

  // const handleSubmit = () => {
  //   const dataToSubmit = {
  //     rows,
  //     tax: parseFloat(tax) || 0,
  //     cab:cabId,
  //     invoice_date:invoiceDate,
  //     invoice_number:invoiceNumber,
  //     sub_total: subTotal,
  //     grand_total: grandTotal,
  //     net_payable: netPayable,
  //   }
  //   console.log('Data to submit:', dataToSubmit)
  //   // Add your submission logic here, e.g., send to an API
  // }
  const handleSubmit = async () => {
    if (!invoiceDate) {
      toast.error('Invoice date is required.')
      return
    }
    if (!invoiceNumber) {
      toast.error('Invoice number is required.')
      return
    }

    if (subTotal <= 0) {
      toast.error('Sub-total must be greater than zero.')
      return
    }
    if (grandTotal <= 0) {
      toast.error('Grand total must be greater than zero.')
      return
    }
    if (netPayable <= 0) {
      toast.error('Net payable amount must be greater than zero.')
      return
    }

    if (!table_data || table_data.length === 0) {
      toast.error('Details and respective cannot be empty.')
      return
    }
    try {
      const jsonData = JSON.stringify(table_data)

      const response = await axios.post(`${BASE_URL}/payment/application/cab/invoice/ `, {
        tax: parseFloat(tax) || 0,
        cab: cabId,
        invoice_date: invoiceDate,
        invoice_number: invoiceNumber,
        sub_total: subTotal,
        grand_total: grandTotal,
        net_payable: netPayable,
        table_data: jsonData,
      })
      if (response.status === 200 || response.status === 201) {
        toast.success('Application fee generated successfully.')
        setInvoiceDate('')
        setInvoiceNumber('')
        setSubTotal(0)
        setGrandTotal(0)
        setNetPayable(0)
        setTax(0)
        setRows([{id: 1, details: ' ', fee: 0, mandayRate: 0, totalAmount: 0}])
      } else {
        toast.error('Please try again.')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='container'>
      <div className='row'>
        <div className='mb-5 col-6'>
          <label className='required form-label'>CAB User</label>
          <Select
            id='select_user'
            name='User'
            options={value}
            className='react-select-styled'
            classNamePrefix='react-select'
            value={cabUser}
            onChange={(e) => {
              setCabUser(e)
              setCabID(e?.value)
            }}
          />
        </div>
        <div className='mb-5 col-6 px-10 py-5 '>
          <h4 className='fw-bolder mb-2'>
            CAB: <span className='inline_master_head'>{cabUser?.label}</span>
          </h4>
        </div>
      </div>
      {cabId ? (
        <div className='py-3'>
          {/* end::Table container */}
          <div className=''>
            <div className='' style={{border: '1px solid #333'}}>
              <div className='d-flex'>
                <div className=''>
                  <img
                    alt='Logo'
                    src={'/media/logos/uaf_logo.png'}
                    className='p-1  app-sidebar-logo-default theme-light-show'
                    style={{
                      width: '250px',
                      height: '120px',
                      border: '1px solid #333',
                    }}
                  />
                </div>
                <div className='text-center' style={{border: '1px solid #333'}}>
                  <h2 style={{padding: '25px 10px 0px 10px'}}>
                    UNITED ACCREDITATION FOUNDATION INC, 1060 LASKIN ROAD, SUITE 12B/13B, VIRGINIA
                    BEACH, VA 23451
                  </h2>
                </div>
              </div>
              <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>Invoice Number </div>
                </div>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='--'
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e?.target?.value)}
                    />
                  </div>
                </div>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>Dated</div>
                </div>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>
                    {' '}
                    <input
                      type='Date'
                      className='form-control form-control-solid'
                      placeholder='--'
                      value={invoiceDate}
                      onChange={(e) => setInvoiceDate(e?.target?.value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                <div className='row text-center' style={{fontSize: '18px'}}>
                  <h2 style={{padding: '20px 0px 20px 0px'}}>Application Fee</h2>
                </div>
              </div>
              <div className='table-responsive'>
                <table
                  className='table-bordered table '
                  style={{border: '1px solid #333', width: ' -webkit-fill-available'}}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Details</th>
                      <th>Fee</th>
                      <th>Manday Rate ($)</th>
                      <th>Total Amount USD($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {table_data.map((row, index) => (
                      <tr key={index}>
                        <td>{row.id}</td>
                        <td>
                          <input
                            type='text'
                            placeholder='Details'
                            value={row.details}
                            onChange={(e) => handleInputChange(index, 'details', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            placeholder='Fee'
                            value={row.fee}
                            onChange={(e) => handleInputChange(index, 'fee', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            placeholder='Manday Rate'
                            value={row.mandayRate}
                            onChange={(e) => handleInputChange(index, 'mandayRate', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            placeholder='Total'
                            value={row.totalAmount}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Sub Total Pre Taxes:
                      </td>
                      <td>
                        <strong>{subTotal.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{textAlign: 'right'}}>
                        Taxes, if any:
                      </td>
                      <td>
                        <input
                          type='number'
                          placeholder='Enter Taxes'
                          value={tax}
                          onChange={(e) => setTax(e.target.value.replace(/^0+/, ''))}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Grand Total
                      </td>
                      <td>
                        <strong>{grandTotal.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Net Payable on account of this Invoice
                      </td>
                      <td>
                        <strong>{netPayable.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Net Amount of Invoice USD ($):
                      </td>
                      <td>
                        <strong>({NumberTOWord(Math.round(netPayable))})</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button onClick={addRow}>Add More</button>
            </div>
          </div>

          <div className='text-gray-800  mb-1'>
            <button className='btn btn-primary' onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CabInitialFee
