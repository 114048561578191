import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import {Typography} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import QMSannexure from './component/QMSannexure'
import EMSannexure from './component/EMSannexure'
import OHSMSannexure from './component/OHSMSannexure'
import FSMSannexure from './component/FSMSannexure'
import MDQMSannexure from './component/MDQMSannexure'
import _ from 'lodash'
import {useParams} from 'react-router-dom'
import {UserDataType} from '../assessor-mangement/AssessorInterface'

type AssessorPlannerData = {
  id: string
  cab_name: string
  assessor_name: {id: string; first_name: string; last_name: string}[]
  scheme_name: string
  assessor_data: {
    location: string
    assessor: string
    assessor__first_name: string
    assessor__last_name: string
  }[]
  assessment_multi_date: string
  assessment_number: string
  assessment_type: string
  assessment_duration: string
  remark: string
  is_approved: boolean
  is_rejected: boolean
  status: string | null
  report: string | null
  report_remark: string | null
  create_at: string
  update_at: string
  scheme: string
  assessor_planner: string
  cab: string
  assessor: string[]
  cluster_values: {id: string; name: string}[]
}

type Props = {
  allAssessorPlannerData: AssessorPlannerData[]
  singleCab: {
    user_schemes: any[]
  }
}
const CreateAnnexureReport = ({allAssessorPlannerData, singleCab}: Props) => {
  const {cab_id, arc_member, arc_no} = useParams()
  const CAB_id = cab_id
  const ARC_member = arc_member
  const ARC_code = arc_no

  const CABSchemeDATA = singleCab?.user_schemes || [];
  console.log(CABSchemeDATA, 'CABDATA')

  const allData = allAssessorPlannerData
  console.log(allData, 'allData')

  const QmsSchemeID = '745048c9-17e9-40bc-a8d7-6ef2a91e9aee'
  const OHSMSID = 'df28ddf3-b330-47d8-abec-a44333e4aabb'
  const FSMSID = '40f65889-bbe5-4784-9e10-38863a220be9'
  const EMSID = '07f858f5-7e61-4780-baf0-b1ff97210de1'
  const MDQMSID = '3d350ed1-ae27-44fe-8b2a-3c6a6029b774'

  const hasQmsScheme = CABSchemeDATA.includes(QmsSchemeID);
  const hasOhsmsScheme = CABSchemeDATA.includes(OHSMSID);
  const hasFsmsScheme = CABSchemeDATA.includes(FSMSID);
  const hasEmsScheme = CABSchemeDATA.includes(EMSID);
  const hasMdqmsScheme = CABSchemeDATA.includes(MDQMSID);
  const noSchemeSelected = !(hasQmsScheme || hasOhsmsScheme || hasFsmsScheme || hasEmsScheme || hasMdqmsScheme);
  console.log(CAB_id, 'CAB_id')

  const [tabValue, setTabValue] = useState('1')
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }
  return (
    <>
      {/* <PageTitle breadcrumbs={accountBreadCrumbs}>Annexure Report</PageTitle> */}
      <h2 className='py-5'>Scopes of Accreditation:</h2>
      

<Box sx={{ width: '100%', typography: 'body1' }}>
    {noSchemeSelected ? (
        <Box sx={{ textAlign: 'center', padding: '16px', color: 'gray' }}>
            <Typography variant="h6">No Management Scheme Selected</Typography>
        </Box>
    ) : (
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    {hasQmsScheme && <Tab label="QMS" value="1" />}
                    {hasEmsScheme && <Tab label="EMS" value="2" />}
                    {hasOhsmsScheme && <Tab label="OHSMS" value="3" />}
                    {hasFsmsScheme && <Tab label="FSMS" value="4" />}
                    {hasMdqmsScheme && <Tab label="MDQMS" value="5" />}
                </TabList>
            </Box>
            {hasQmsScheme && (
                <TabPanel value="1">
                    <QMSannexure uniqueCab={CAB_id} ARC_member={ARC_member} ARC_code={ARC_code} />
                </TabPanel>
            )}
            {hasEmsScheme && (
                <TabPanel value="2">
                    <EMSannexure uniqueCab={CAB_id} ARC_member={ARC_member} ARC_code={ARC_code} />
                </TabPanel>
            )}
            {hasOhsmsScheme && (
                <TabPanel value="3">
                    <OHSMSannexure uniqueCab={CAB_id} ARC_member={ARC_member} ARC_code={ARC_code} />
                </TabPanel>
            )}
            {hasFsmsScheme && (
                <TabPanel value="4">
                    <FSMSannexure uniqueCab={CAB_id} ARC_member={ARC_member} ARC_code={ARC_code} />
                </TabPanel>
            )}
            {hasMdqmsScheme && (
                <TabPanel value="5">
                    <MDQMSannexure uniqueCab={CAB_id} ARC_member={ARC_member} ARC_code={ARC_code} />
                </TabPanel>
            )}
        </TabContext>
    )}
</Box>
      
    </>
  )
}

export default CreateAnnexureReport
