import React from 'react'
import FolderHierarchy from '../../../../hierarchy/FolderHierarchy'
interface AssessorIDProps {
  userId: string
}
const VerifiedAssessorsFiles: React.FC<AssessorIDProps> = ({userId}) => {
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]
  return (
    <div id='doc_1'>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Documents
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>100+ resources</span> */}
        </h3>
      </div>
      <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        <div  className='col-12 col-sm-12 col-xl'>
            <div className='card h-100'>
              <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
              <FolderHierarchy urlId={urlId} />
              </div>
            </div>
          </div>
       </div>
    </div>
  )
}

export {VerifiedAssessorsFiles}
