import axios from 'axios';
import React, { useEffect, useState } from 'react'
type Props={
    userId: string | undefined;
    ARC_member: string | undefined;
    ARC_no: string | undefined;
    }
const GenerateMDQMSax = ({ userId ,ARC_member,ARC_no }: Props) => {
    const BASE_URL = process.env.REACT_APP_API_URL
     const MDQMSSchemeID = "3d350ed1-ae27-44fe-8b2a-3c6a6029b774"
    const [mdqmsAnnexure, setMDQMSAnnxeure] = useState({


        iaf_1_1: "Non-active Medical Devices",
        iaf_1_2: "",
        iaf_1_3: "",
        iaf_1_4: "",
        iaf_1_5: "",
        iaf_1_6: "",
        iaf_1_7: "Active Medical Devices (Non-implantable)",
        iaf_1_8: "",

        iaf_1_9: "",
        iaf_1_10: "",
        iaf_1_11: "",
        iaf_1_12: "",
        iaf_1_13: "Active Implantable Medical Devices",
        iaf_1_14: "",
        iaf_1_15: "",
        iaf_1_16: "",

        iaf_1_17: "",
        iaf_1_18: "",
        iaf_1_19: "In Vitro Diagnostic Medical Devices (IVD) ",
        iaf_1_20: "",
        iaf_1_21: "",
        iaf_1_22: "",
        iaf_1_23: "",
        iaf_1_24: "",

        iaf_1_25: "Sterilization Method for Medical Devices",
        iaf_1_26: "",
        iaf_1_27: "",
        iaf_1_28: "",
        iaf_1_29: "",
        iaf_1_30: "",
        iaf_1_31: "Devices Incorporating/Utilizing Specific Substances/Technologies",
        iaf_1_32: "",


        iaf_1_33: "",
        iaf_1_34: "",
        iaf_1_35: "",
        iaf_1_36: "",
        iaf_1_37: "Parts or Services",
        iaf_1_38: "",
        iaf_1_39: "",
        iaf_1_40: "",

        iaf_1_41: "",
        iaf_1_42: "",




    });
    useEffect(()=>{
        const getAnnexureData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/arc/anexxure/report/list/?cab=${userId}&assessor=${ARC_member}&scheme=${MDQMSSchemeID}&create_at=&update_at=`);

                if (response.status === 200 || response.status === 201) {
                    console.log(response.data, "DATA");
                    const jsonData = response.data[0]?.json_value; // Assuming json_value is in the first object

                    if (jsonData) {
                        setMDQMSAnnxeure(prevState => ({
                            ...prevState,
                            ...jsonData
                        }));
                    }
                }
            } catch (error) {
                console.log(error);

            }
        };


         getAnnexureData();
       },[])
  return (
    <div className=' mb-5 mb-xl-10' id='kt_profile_details_view'
    style={{width:"100%",
        background: "#fff",
        padding: "25px",
        borderRadius: "5px"
      }}
    >
    <div className='card-body p-9'>
    <h2 className='text-center'>Medical Devices Quality Management System </h2>
    <h3 className='text-center mb-10 py-5 fs-5 ' style={{borderBottom:"1px solid #f3f3f3"}}>CHECKLIST FOR THE AWARD OF SCOPES FOR MS DURING INITIAL ACCREDITATION</h3>
        <div className='mb-5 col-lg-12'>
            {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
            <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                <thead>
                    <tr className='text-start  fw-bold  text-uppercase gs-0'>
                        <th className='w-250px pe-2 fs-7'>Main Technical Area (preferably High Risk)</th>
                        <th className='w-250px pe-2 fs-7'>Technical Areas Applied by CAB</th>
                        <th className='w-250px pe-2 fs-7'>Details of the demonstration of the competent personnel for all technical areas during office assessment</th>
                        <th className='w-250px pe-2 fs-7'>Witness Details for the Technical Areas (Technical Area, Date of Witness and Assessment Number)</th>
                        <th className='w-250px pe-2 fs-7'>Remarks by the Senior Executive/Manager Accreditation list of technical areas to be awarded</th>
                        <th className='w-250px pe-2 fs-7'>Decision/Remarks by ARC Member
                            (Awarded/Refused)</th>
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold' style={{ maxHeight: '400px', overflowY: 'auto' }}>

                    <tr>
                        <td >

                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_1: e?.target?.value })}
                                value={mdqmsAnnexure.iaf_1_1}


                            />
                        </td>
                        <td>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_2: e?.target?.value })}
                                value={mdqmsAnnexure.iaf_1_2}

                            />
                        </td>
                        <td > <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_3: e?.target?.value })}
                            value={mdqmsAnnexure.iaf_1_3}

                        /></td>
                        <td ><textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_4: e?.target?.value })}
                            value={mdqmsAnnexure?.iaf_1_4}

                        /></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_5: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_5}
                            />
                        </div></td>
                        <td > <div className='text-gray-800 mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_6: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_6}
                            />

                        </div></td>

                    </tr>

                    <tr>
                    <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_7: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_7}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_8: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_8}
                            />
                        </div></td>

                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_9: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_9}
                            />
                        </div></td>
                        <td>
                            <div className='text-gray-800 mb-1'>
                                <div className=''>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        readOnly
                                        // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_10: e?.target?.value })}
                                        value={mdqmsAnnexure?.iaf_1_10}
                                    />
                                </div>
                            </div>
                        </td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_11: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_11}
                            />
                        </div></td>

                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_12: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_12}
                            />
                        </div></td>


                    </tr>


                    <tr>

                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_13: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_13}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_14: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_14}
                            />
                        </div></td>
                    <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_15: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_15}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_16: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_16}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_17: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_17}
                            />
                        </div></td>

                        <td>
                            <div className='text-gray-800 mb-1'>
                                <div className=''>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        readOnly
                                        // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_18: e?.target?.value })}
                                        value={mdqmsAnnexure?.iaf_1_18}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>

                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_19: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_19}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_20: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_20}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_21: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_21}
                            />
                        </div></td>
                    <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_22: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_22}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_23: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_23}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_24: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_24}
                            />
                        </div></td>

                    </tr>

                    <tr>

                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_25: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_25}
                            />
                        </div></td>
                        <td>
                            <div className='text-gray-800 mb-1'>
                                <div className=''>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        readOnly
                                        // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_26: e?.target?.value })}
                                        value={mdqmsAnnexure?.iaf_1_26}
                                    />
                                </div>
                            </div>
                        </td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_27: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_27}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_28: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_28}
                            />
                        </div></td>
                    <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_29: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_29}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_30: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_30}
                            />
                        </div></td>

                    </tr>

                    <tr>

                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_31: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_31}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_32: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_32}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_33: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_33}
                            />
                        </div></td>
                        <td>
                            <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_34: e?.target?.value })}
                                    value={mdqmsAnnexure?.iaf_1_34}
                                />
                            </div>
                        </td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_35: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_35}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_36: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_36}
                            />
                        </div></td>

                    </tr>

                    <tr>

                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_37: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_37}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_38: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_38}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_39: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_39}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_40: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_40}
                            />
                        </div></td>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_41: e?.target?.value })}
                                value={mdqmsAnnexure?.iaf_1_41}
                            />
                        </div></td>
                        <td>
                            <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setMDQMSAnnxeure({ ...mdqmsAnnexure, iaf_1_42: e?.target?.value })}
                                    value={mdqmsAnnexure?.iaf_1_42}
                                />
                            </div>
                        </td>



                    </tr>




                </tbody>
            </table>

        </div>
    </div>
</div>
  )
}

export default GenerateMDQMSax