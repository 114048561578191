import React, {useCallback, useEffect, useState} from 'react'
import {Dropdown, DropdownButton} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import NCReport from './NCReport'
import debounce from 'lodash/debounce'
import * as XLSX from 'xlsx'
import {usePermissions} from '../../permission_component/PermissionContext'

interface NCList {
  id: string
  category_name: string
  ncr_status_name: string
  registrar_name: string
  registrar_company: string
  assessment_name: string
  assessment_stage_name: string
  assessment_number: string
  ncr_number: string
  date_evidence: string
  create_at: string
  cab_representative: string
  team_leader_list: {id: string; first_name: string; last_name: string}[]
  scheme_list: {id: string; name: string; short_name: string}[]
  standard_list: {id: string; name: string}[]
  standard_clause: string
  action_status: string
  registrar: string
}

const NCManagementList: React.FC = () => {
  const {assessorPermission, cabPermission, superUserPermission, updatePermissions} =
    usePermissions()
  const currentUserId = localStorage.getItem('current_user_id')
  const BASE_URL = process.env.REACT_APP_API_URL
  const [value, setValue] = useState<any[]>([])
  const [loader, setLoader] = useState<any>(false)
  const [totalcount, settotalcount] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredValue, setFilteredValue] = useState<NCList[]>([])

  const [typeFilter, setTypeFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [dateFilter, setDateFilter] = useState('')

  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const formatDate = (dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY')
  }

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value)
  // }
  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value
      console.log(searchValue, 'searchValue')
      if (searchValue) {
        try {
          // setLoader(true);
          const response = await axios.get(
            `${BASE_URL}/nc/management/get/list/pagination/?search=${searchValue}`
          )
          // settotalcount(response?.data?.count);
          if (response.status === 200) {
            setFilteredValue(response.data?.results)
          }
        } catch (error) {
          console.log(error)
        } finally {
          // setLoader(false);
        }
      } else {
        setFilteredValue([])
        fetchData()
      }
    }, 500),
    []
  )
  // const handleTypeFilterChange = (type) => {
  //   setTypeFilter(type)
  // }

  // const handleStatusFilterChange = (status) => {
  //   setStatusFilter(status)
  // }

  const handleDateFilterChange = (dateRange) => {
    setDateFilter(dateRange)
  }

  const applyFilters = (data) => {
    let filteredData = data

    if (dateFilter) {
      const now = moment()
      filteredData = filteredData.filter((item) => {
        const raiseDate = moment(item.create_at)
        switch (dateFilter) {
          case '1month':
            return raiseDate.isAfter(now.clone().subtract(1, 'month'))
          case '3months':
            return raiseDate.isAfter(now.clone().subtract(3, 'months'))
          case '6months':
            return raiseDate.isAfter(now.clone().subtract(6, 'months'))
          case '1year':
            return raiseDate.isAfter(now.clone().subtract(1, 'year'))
          default:
            return true
        }
      })
    }

    return filteredData
  }

  const exportToExcel = async () => {
    const response = await axios.get(`${BASE_URL}/nc/management/get/list/`)
    const dataToExport = response.data.map((item, index) => ({
      'SR#': index + 1,
      CAB: item.registrar_company,
      'Assessment#': item.assessment_number,
      'Assessment Type': item.assessment_name,
      'NC#': item.ncr_number,
      Type: item.category_name,
      Status: item.ncr_status_name,
      Standard: item.standard_clause,
      'Raise Date': item.create_at ? formatDate(item.create_at) : '',
      'Team Lead': item.team_leader_list
        .map((leader) => leader?.first_name + ' ' + leader?.last_name)
        .join(', '),
      'CAB Representative': item.cab_representative,
    }))

    const worksheet = XLSX.utils.json_to_sheet(dataToExport)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'NC List')

    XLSX.writeFile(workbook, 'NC_Management_List.xlsx')
  }

  const fetchData = async () => {
    try {
      const params = new URLSearchParams()

      // Ensure page is passed as a string
      params.append('page', String(pagenumber || 1)) // Convert to string

      const userId = currentUserId || ''
      if (assessorPermission?.is_verify_accessor) {
        params.append('team_leader', userId)
        // If team_member is available, pass the same userId, otherwise pass an empty string
        params.append('team_member', userId || '')
      }

      const response = await axios.get(
        `${BASE_URL}/nc/management/get/list/pagination/?${params.toString()}`
      )

      if (response.status === 200) {
        const {count, results} = response.data
        settotalcount(count)
        setTotalPages(Math.ceil(count / 10))
        setValue(results)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [pagenumber, assessorPermission?.is_verify_accessor, currentUserId])

  useEffect(() => {
    setFilteredValue(applyFilters(value))
  }, [dateFilter, value])

  const totalPagesArray = Array.from({length: totalPages}, (_, index) => index + 1)
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page)
    }
  }

  return (
    <div id='nc_list_id'>
      <h1 className='mb-5'>NC List</h1>
      <div className='card card-flush'>
        <div className='card-header pt-5'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  />
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <input
                type='text'
                id='search_deatils'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchChange(e)
                  }
                }}
                placeholder='Search'
              />
              <div className='d-flex justify-content-between'>
                <DropdownButton id='date-filter' title='Filter by Raise Date' className='m-5'>
                  <Dropdown.Item onClick={() => handleDateFilterChange('')}>All</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDateFilterChange('1month')}>
                    Last 1 Month
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDateFilterChange('3months')}>
                    Last 3 Months
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDateFilterChange('6months')}>
                    Last 6 Months
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDateFilterChange('1year')}>
                    Last 1 Year
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <Link id='search_deatils_2' to='/NCManagement/form/' className='btn btn-primary'>
                <span className='svg-icon svg-icon-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='11.364'
                      y='20.364'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-90 11.364 20.364)'
                      fill='currentColor'
                    />
                    <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='currentColor' />
                  </svg>
                </span>
                Create NC
              </Link>
              <button
                style={{marginLeft: '20px'}}
                className='btn btn-secondary'
                onClick={exportToExcel}
              >
                Export to Excel
              </button>
            </div>
          </div>
        </div>

        <div className='card-body pt-5 table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='min-w-50px'>SR#</th>
                <th className='min-w-125px'>CAB</th>
                <th className='min-w-125px'>Assessment#</th>
                <th className='min-w-150px'>Assessment Stage</th>
                <th className='min-w-150px'>Assessment Type</th>
                <th className='min-w-130px'>NC#</th>
                <th className='min-w-120px'>Type</th>
                {/* <th className='min-w-120px'>Stage</th> */}
                <th className='min-w-110px'>Status</th>
                <th className='min-w-125px'>Standard</th>
                <th className='min-w-125px'>Raise Date</th>
                {/* <th className='min-w-125px'>Submission Date</th> */}
                <th className='min-w-125px'>Team Lead</th>
                <th className='min-w-125px'>CAB Representative</th>
                <th className='min-w-70px'>Report</th>
                <th className='text-center min-w-100px'>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {(filteredValue?.length > 0 ? filteredValue : value)?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>
                    <Link
                      id='search_deatils_3'
                      to={`/crafted/pages/profile/${item?.registrar}/overview`}
                      className='text-gray-800 text-hover-primary mb-1'
                    >
                      {item?.registrar_company}
                    </Link>
                  </td>
                  <td>
                    <Link
                      id='search_deatils_4'
                      to={`/crafted/pages/profile/${item?.registrar}/overview`}
                      className='text-gray-800 text-hover-primary mb-1'
                    >
                      {item?.assessment_number}
                    </Link>
                  </td>

                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      {item?.assessment_stage_name}
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      {item?.assessment_name}
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>{item?.ncr_number}</div>
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      {item?.category_name}
                    </div>
                  </td>
                  {/* <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.action_status}</div>
                </td> */}
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      {item?.ncr_status_name}
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      {item?.standard_clause}
                    </div>
                  </td>
                  <td>{item && item.create_at ? formatDate(item.create_at) : ''}</td>
                  {/* <td>{item && item.date_evidence ? formatDate(item.date_evidence) : ''}</td> */}
                  <td>
                    {item?.team_leader_list
                      .map((leader) => leader?.first_name + ' ' + leader?.last_name)
                      .join(', ')}
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      {item?.cab_representative}
                    </div>
                  </td>
                  <td>
                    <a
                      href={`/apps/cab/nc/report/${item?.id}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src='/media/doc.png' alt='' />
                    </a>
                  </td>

                  <td className='text-center'>
                    <Link id='search_deatils_6' to={`/NCManagement/edit/${item?.id}/`}>
                      <i
                        className='bi bi-pencil-square text-primary'
                        style={{fontSize: '25px', cursor: 'pointer'}}
                      ></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul className='pagination'>
            <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
              <a
                id='search_deatils_7'
                className='page-link'
                onClick={() => handlePageChange(pagenumber - 1)}
              >
                <i className='previous'></i>
              </a>
            </li>
            {totalPagesArray.map((page, index) => (
              <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
                <a
                  id={`hello_nand_${index}`}
                  className='page-link'
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </a>
              </li>
            ))}
            <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
              <a
                id='search_deatils_8'
                className='page-link'
                onClick={() => handlePageChange(pagenumber + 1)}
              >
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {NCManagementList}
