import axios from 'axios';
import React, { useEffect, useState } from 'react'
type Props={
    userId: string | undefined;
    ARC_member: string | undefined;
    ARC_no: string | undefined;
    }
const GenerateEMSax = ({ userId ,ARC_member,ARC_no }: Props) => {
    const BASE_URL = process.env.REACT_APP_API_URL
    const EMSSchemeID = "07f858f5-7e61-4780-baf0-b1ff97210de1"
    const [emsAnnexure, setEMSAnnxeure] = useState({

        iaf_1_1: "1",
        iaf_1_2: "Nil",
        iaf_1_3: "1",
        iaf_1_4: "",
        iaf_1_5: "",
        iaf_1_6: "",
        iaf_1_7: "",
        iaf_1_8: "",
        iaf_1_9: "03 and 30",
        iaf_1_10: "30",
        iaf_1_11: "3",
        iaf_1_12: "",
        iaf_1_13: "",
        iaf_1_14: "",
        iaf_1_15: "",
        iaf_1_16: "",
        iaf_1_17: "07a,08,09",
        iaf_1_18: "07a,08",
        iaf_1_19: "9",
        iaf_1_20: "",
        iaf_1_21: "",
        iaf_1_22: "",
        iaf_1_23: "",
        iaf_1_24: "",
        iaf_1_25: "17a, 18, 19, 20, 21, 22",
        iaf_1_26: "17a, 18, 19, 22",
        iaf_1_27: "20 or 21",
        iaf_1_28: "",
        iaf_1_29: "",
        iaf_1_30: "",
        iaf_1_31: "",
        iaf_1_32: "",
        iaf_1_33: "28,34",
        iaf_1_34: "34",
        iaf_1_35: "28",
        iaf_1_36: "",
        iaf_1_37: "",
        iaf_1_38: "",
        iaf_1_39: "",
        iaf_1_40: "",
        iaf_1_41: "04,05,06,23",
        iaf_1_42: "06, 23",
        iaf_1_43: "05 and 14",
        iaf_1_44: "",
        iaf_1_45: "",
        iaf_1_46: "",
        iaf_1_47: "",
        iaf_1_48: "",
        iaf_1_49: "07b, 10, 12, 13, 14, 15, 16, 17b",
        iaf_1_50: "14,15,16,17b",
        iaf_1_51: "07b and 10 and 12 and 13",
        iaf_1_52: "",
        iaf_1_53: "",
        iaf_1_54: "",
        iaf_1_55: "",
        iaf_1_56: "",
        iaf_1_57: "2",
        iaf_1_58: "Nil",
        iaf_1_59: "2",
        iaf_1_60: "",
        iaf_1_61: "",
        iaf_1_62: "",
        iaf_1_63: "",
        iaf_1_64: "",
        iaf_1_65: "25,26,27",
        iaf_1_66: "27",
        iaf_1_67: "25 or 26",
        iaf_1_68: "",
        iaf_1_69: "",
        iaf_1_70: "",
        iaf_1_71: "",
        iaf_1_72: "",
        iaf_1_73: "31,24,39",
        iaf_1_74: "31",
        iaf_1_75: "24 and 39",
        iaf_1_76: "",
        iaf_1_77: "",
        iaf_1_78: "",
        iaf_1_79: "",
        iaf_1_80: "",
        iaf_1_81: "29,32,33,35,36,37",
        iaf_1_82: "32,33,37",
        iaf_1_83: "29 or 35 or 36",
        iaf_1_84: "",
        iaf_1_85: "",
        iaf_1_86: "",
        iaf_1_87: "",
        iaf_1_88: "",
        iaf_1_89: "11",
        iaf_1_90: "Nil",
        iaf_1_91: "11",
        iaf_1_92: "",
        iaf_1_93: "",
        iaf_1_94: "",
        iaf_1_95: "",
        iaf_1_96: "",
        iaf_1_97: "38",
        iaf_1_98: "Nil",
        iaf_1_99: "38",
        iaf_1_100: "",
        iaf_1_101: "",
        iaf_1_102: "",
        iaf_1_103: "",
        iaf_1_104: ""


    });
    useEffect(()=>{
        const getAnnexureData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/arc/anexxure/report/list/?cab=${userId}&assessor=${ARC_member}&scheme=${EMSSchemeID}&create_at=&update_at=`);

                if (response.status === 200 || response.status === 201) {
                    console.log(response.data, "DATA");
                    const jsonData = response.data[0]?.json_value; // Assuming json_value is in the first object

                    if (jsonData) {
                        setEMSAnnxeure(prevState => ({
                            ...prevState,
                            ...jsonData
                        }));
                    }
                }
            } catch (error) {
                console.log(error);

            }
        };


         getAnnexureData();
       },[])
  return (
    <div className=' mb-5 mb-xl-10' id='kt_profile_details_view'
    style={{width:"100%",
        background: "#fff",
        padding: "25px",
        borderRadius: "5px"
      }}
    >
    <div className='card-body p-9'>
    <h2 className='text-center'>Environment Management Systems EMS (IAF Codes 1 to 39)</h2>
    <h3 className='text-center mb-10 py-5 fs-5 ' style={{borderBottom:"1px solid #f3f3f3"}}>CHECKLIST FOR THE AWARD OF SCOPES FOR MS DURING INITIAL ACCREDITATION</h3>
        <div className='mb-5 col-lg-12'>
            {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
            <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                <thead>
                    <tr className='text-start  fw-bold  text-uppercase gs-0'>
                        <th className='w-150px pe-2 fs-7'>IAF codes</th>
                        <th className='w-150px pe-2 fs-7'>Non-critical Codes</th>
                        <th className='w-150px pe-2 fs-7'>Critical Codes</th>
                        <th className='w-250px pe-2 fs-7'>Codes Applied by CAB</th>
                        <th className='w-250px pe-2 fs-7'>Details of the demonstration of the competent personnel for non-critical IAF codes during office assessment</th>
                        <th className='w-250px pe-2 fs-7'>Witness Details for Critical Codes, (IAF Code, Date of Witness and Assessment Number)</th>
                        <th className='w-250px pe-2 fs-7'>Remarks by the Senior Executive/Manager Accreditation for the codes to be awarded based on the requirements</th>
                        <th className='w-250px pe-2 fs-7'>Decision
                            /Remarks by ARC Member
                            (Awarded/Refused)</th>

                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold' style={{ maxHeight: '400px', overflowY: 'auto' }}>

                        <tr>
                            <td >

                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_1: e?.target?.value })}
                                    value={emsAnnexure.iaf_1_1}


                                />
                            </td>
                            <td>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_2: e?.target?.value })}
                                    value={emsAnnexure.iaf_1_2}

                                />
                            </td>
                            <td > <textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_3: e?.target?.value })}
                                value={emsAnnexure.iaf_1_3}

                            /></td>
                            <td ><textarea
                                className='form-control form-control-solid'
                                rows={5}
                                readOnly
                                // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_4: e?.target?.value })}
                                value={emsAnnexure?.iaf_1_4}

                            /></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_5: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_5}
                                />
                            </div></td>
                            <td > <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_6: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_6}
                                />

                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_7: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_7}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_8: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_8}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_9: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_9}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                    <div className=''>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                            readOnly
                                            // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_10: e?.target?.value })}
                                            value={emsAnnexure?.iaf_1_10}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_11: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_11}
                                />
                            </div></td>


                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_12: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_12}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_13: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_13}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_14: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_14}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_15: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_15}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_16: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_16}
                                />
                            </div></td>
                        </tr>


                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_17: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_17}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                    <div className=''>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                            readOnly
                                            // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_18: e?.target?.value })}
                                            value={emsAnnexure?.iaf_1_18}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_19: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_19}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_20: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_20}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_21: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_21}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_22: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_22}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_23: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_23}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_24: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_24}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_25: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_25}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                    <div className=''>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                            readOnly
                                            // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_26: e?.target?.value })}
                                            value={emsAnnexure?.iaf_1_26}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_27: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_27}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_28: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_28}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_29: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_29}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_30: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_30}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_31: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_31}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_32: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_32}
                                />
                            </div></td>
                        </tr>

                         <tr>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_33: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_33}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        readOnly
                                        // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_34: e?.target?.value })}
                                        value={emsAnnexure?.iaf_1_34}
                                    />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_35: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_35}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_36: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_36}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_37: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_37}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_38: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_38}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_39: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_39}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_40: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_40}
                                />
                            </div></td>
                        </tr> 

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_41: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_41}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        readOnly
                                        // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_42: e?.target?.value })}
                                        value={emsAnnexure?.iaf_1_42}
                                    />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_43: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_43}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_44: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_44}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_45: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_45}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_46: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_46}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_47: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_47}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_48: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_48}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_49: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_49}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                    <div className=''>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                            readOnly
                                            // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_50: e?.target?.value })}
                                            value={emsAnnexure?.iaf_1_50}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_51: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_51}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_52: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_52}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_53: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_53}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_54: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_54}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_55: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_55}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_56: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_56}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_57: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_57}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_58: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_58}
                                />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_59: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_59}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_60: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_60}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_61: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_61}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_62: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_62}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_63: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_63}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_64: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_64}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_65: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_65}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_66: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_66}
                                />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_67: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_67}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_68: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_68}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_69: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_69}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_70: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_70}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_71: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_71}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_72: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_72}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_73: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_73}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_74: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_74}
                                />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_75: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_75}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_76: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_76}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_77: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_77}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_78: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_78}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_79: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_79}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_80: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_80}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_81: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_81}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_82: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_82}
                                />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_83: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_83}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_84: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_84}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_85: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_85}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_86: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_86}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_87: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_87}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_88: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_88}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_89: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_89}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_90: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_90}
                                />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_91: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_91}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_92: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_92}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_93: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_93}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_94: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_94}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_95: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_95}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_96: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_96}
                                />
                            </div></td>
                        </tr>

                        <tr>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_97: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_97}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_98: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_98}
                                />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_99: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_99}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_100: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_100}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_101: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_101}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_102: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_102}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_103: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_103}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_104: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_104}
                                />
                            </div></td>

                        </tr>

                      {/*  <tr>
                        <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_105: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_105}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_106: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_106}
                                />
                            </div></td>
                            <td>
                                <div className='text-gray-800 mb-1'>
                                <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_107: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_107}
                                />
                                </div>
                            </td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_108: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_108}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_109: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_109}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_110: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_110}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_111: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_111}
                                />
                            </div></td>
                            <td ><div className='text-gray-800  mb-1'>
                            <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    readOnly
                                    // onChange={(e) => setEMSAnnxeure({ ...emsAnnexure, iaf_1_112: e?.target?.value })}
                                    value={emsAnnexure?.iaf_1_112}
                                />
                            </div></td>

                        </tr>
                        */}

                    </tbody>
            </table>


        </div>
    </div>
</div>
  )
}

export default GenerateEMSax